import { styled } from "styled-components";

export const FooterStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    flex-grow: 0;
    bottom: 0;
    padding-bottom: 0.5rem;
`