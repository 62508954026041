import styled from "styled-components"
import { MediaMeta } from "../models/ImageExpandContext";
import { MediaMessagePart, Message, MessagePartType, MessageSide } from "../models/Message"
import { CHAT_MESSAGE_MAX_WIDTH } from "../services/utils";
import ImageGallary from "./ImageGallery";
import TypingDots from "./TypingDots";
import VideoMessage from "./VideoMessage";
import { ENVIRONMENT } from "../services/utils";
import Spinner from "./Spinner"


const MessageBubble = (props: TextBubbleProps) => {

  if (props.message === undefined && props.isTypingMessage === undefined)
    throw Error("MessageBubble must be privided one of message or isTypingMessage properties");

  let imageMetas: MediaMeta[] = props.message === undefined ? [] : props.message.payload.filter(x => x.type === MessagePartType.Image).map((x) => {
    const imageMessagePart = x as MediaMessagePart;
    return {
      url: x.data,
      aspectRatio: imageMessagePart.aspectRatio,
      width: imageMessagePart.width,
      height: imageMessagePart.height,
      previewUrl: imageMessagePart.previewUrl
    }
  });
  let videoMetas: MediaMeta[] = props.message === undefined ? [] : props.message.payload.filter(x => x.type === MessagePartType.Video).map((x) => {
    const videoMessagePart = x as MediaMessagePart;
    return {
      url: x.data,
      aspectRatio: videoMessagePart.aspectRatio,
      width: videoMessagePart.width,
      height: videoMessagePart.height,
      previewUrl: videoMessagePart.previewUrl
    }
  });

  let texts: string[] = props.message === undefined ? [] : props.message.payload.filter(x => x.type === MessagePartType.Text).map(x => x.data);

  const gap: number = 8;

  const side: MessageSide = props.isTypingMessage === true
    ? MessageSide.Bot
    : props.message!.side;

  let content = <></>
  if (props.isTypingMessage)
    content = (
      <MessageTextStyled $side={side} $last={props.last}>
        <TypingDots />
      </MessageTextStyled>
    )
  else if (videoMetas.length > 0)
    content = (
      <VideoMessage src={videoMetas[0].url} aspectRatio={videoMetas[0].aspectRatio}
        posterSrc={videoMetas[0].previewUrl} text={texts.length > 0 ? texts.join('\n') : ""} last={props.last}
      />
    )
  else if (imageMetas.length > 0)
    content = (
      <ImageGallary imageMetas={imageMetas} gap={gap} text={texts.length > 0 ? texts.join('\n') : ""} side={props.message!.side} last={props.last} />
    )
  else if (imageMetas.length === 0 && texts.length > 0)
    content = (
      <MessageContainerStyled $side={side}>
        <MessageTextStyled $side={side} $last={props.last}>
          {props.message?.isVoiceMessage === true && ENVIRONMENT === "production" && <GraphicEqOutlinedIcon />}
          <span
            dir="auto"
            style={{ display: "block", textAlign: "start" }}
            dangerouslySetInnerHTML={{ __html: props.message?.isVoiceMessage === true && ENVIRONMENT === "production" ? props.audioMessageText : texts.join('\n') }}>
          </span>
        </MessageTextStyled>
        {props.message!.reaction && (
          <ReactionStyled>
            <ReactionSpanStyled>{props.message!.reaction}</ReactionSpanStyled>
          </ReactionStyled>
        )}
      </MessageContainerStyled>
    )
  else if (texts.length === 0 && props.message?.isVoiceMessage === true)
    content = (
      <MessageContainerStyled $side={side}>
        <MessageTextStyled $side={side} $last={props.last}>
          <Spinner />
        </MessageTextStyled>
      </MessageContainerStyled>
    )
  else
    throw Error("Nothing to display in message");

  return (
    <TextBubbleStyled className="text-bubble" $maxWidth={props.maxWidth} $flexBasis={props.flexBasis}
      $imageCount={imageMetas.length} $hasText={texts.length > 0} $side={side}>
      {content}
      {props.last && (
        <TextBubbleTailStyled $side={side}>
          <svg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12H9.35216C9.87414 12 10.0021 11.2735 9.51158 11.0951L8.57685 10.7552C3.92594 9.06398 0.613831 4.91065 0 0V12Z' fill='white' />
            <PathStyled d='M0 12H9.35216C9.87414 12 10.0021 11.2735 9.51158 11.0951L8.57685 10.7552C3.92594 9.06398 0.613831 4.91065 0 0V12Z' />
          </svg>
        </TextBubbleTailStyled>
      )}
    </TextBubbleStyled>
  )
}

const GraphicEqOutlinedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 16H7V12H5V16Z" fill="currentColor" />
    <path d="M9 18H11V10H9V18Z" fill="currentColor" />
    <path d="M13 20H15V8H13V20Z" fill="currentColor" />
    <path d="M17 18H19V10H17V18Z" fill="currentColor" />
    <path d="M21 16H23V12H21V16Z" fill="currentColor" />
  </svg>
);

const ReactionSpanStyled = styled.span`
  line-height: 1rem;
`

const ReactionStyled = styled.div`
  position: absolute;
  transform: translate(0, 50%);
  bottom: 0;
  left: 0;
  border-radius: 1rem;
  padding: 0.25rem;
  line-height: initial;
  box-shadow: 0 0px 5px 1px #ffffff;
  background-color: var(--background-color);
  width: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
`

const MessageContainerStyled = styled.div<{ $side: MessageSide }>`
  display: flex;
  color: var(--color);
  border-radius: 8px;
  border-bottom-right-radius: 0;
  justify-content: ${props => props.$side === MessageSide.Bot ? 'start' : 'end'};
  width: fit-content;
  align-self: ${props => props.$side === MessageSide.Bot ? 'start' : 'end'};
  position: relative;
`

const PathStyled = styled.path`
  fill: var(--background-color);
`

const TextBubbleTailStyled = styled.div<{ $side: MessageSide }>`
  position: absolute;
  bottom: 0;
  display: flex;
  
  &:dir(ltr) {
    ${props => props.$side === MessageSide.Bot ? "right" : "left"}: 100%;
    ${props => props.$side === MessageSide.Bot ? "transform: scaleX(-1);" : ""}
  }

  &:dir(rtl) {
    ${props => props.$side === MessageSide.User ? "right" : "left"}: 100%;
    ${props => props.$side === MessageSide.User ? "transform: scaleX(-1);" : ""}
  }
`

const MessageTextStyled = styled.span<{ $side: MessageSide, $last: boolean }>`
  background-color: var(--background-color);
  color: var(--color);
  padding: 8px 16px 12px 16px;
  border-radius: 8px;
  width: fit-content;
  align-self: ${props => props.$side === MessageSide.Bot ? 'start' : 'end'};
  
  &:dir(ltr) {
    ${props => props.$last && props.$side === MessageSide.Bot ? "border-bottom-left-radius: 0;" : ""}
    ${props => props.$last && props.$side === MessageSide.User ? "border-bottom-right-radius: 0;" : ""}
  }

  &:dir(rtl) {
    ${props => props.$last && props.$side === MessageSide.User ? "border-bottom-left-radius: 0;" : ""}
    ${props => props.$last && props.$side === MessageSide.Bot ? "border-bottom-right-radius: 0;" : ""}
  }
`

const TextBubbleStyled = styled.div<TextBubbleStyledProps>`
  display: flex;
  justify-content: end;
  flex-direction: column;
  white-space: pre-wrap;
  --background-color: ${props => props.$side === MessageSide.Bot ? '#F2F2F7' : '#E4ECFF'};
  --color: #2C2C2E;
  word-break: break-word;
  max-width: ${props => props.$maxWidth ? props.$maxWidth : `${CHAT_MESSAGE_MAX_WIDTH}px`};
  position: relative;
  width: 100%;

  &:dir(ltr) {
    text-align: left;
  }

  &:dir(rtl) {
    text-align: right;
  }

  @media (max-width: 800px) {
    ${props => props.$imageCount > 0 ? `width: 100%` : ""};
  }

  @media (min-width: 800px) {
    ${props => props.$imageCount > 1 && props.$maxWidth ? `width: ${props.$maxWidth}` : ""};
  }
`

interface TextBubbleStyledProps {
  $side: MessageSide,
  // $isTypingMessage?: boolean,
  $minWidth?: number,
  $maxWidth?: string,
  $flexBasis?: string,
  $imageCount: number,
  $hasText: boolean
}

interface TextBubbleProps {
  message?: Message,
  audioMessageText: string,
  isTypingMessage?: boolean,
  maxWidth?: string,
  flexBasis?: string,
  last: boolean
}

export default MessageBubble