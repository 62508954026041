import { useState } from "react";
import { styled } from "styled-components";
import Video from "./Video/Video";
import { VideoProvider } from "./Video/VideoContextProvider";

interface VideoMessageProps {
    src: string,
    aspectRatio: number,
    last: boolean,
    posterSrc?: string,
    text?: string,
}

const VideoMessage = (props: VideoMessageProps) => {

    const [width, setWidth] = useState<number>(0);

    return (
        <VideoMessageStyled>
            <VideoProvider>
                <Video src={props.src} aspectRatio={props.aspectRatio} posterSrc={props.posterSrc} widthCallback={setWidth} />
            </VideoProvider>
            {props.text && <MessageTextStyled $last={props.last} $width={width} dangerouslySetInnerHTML={{ __html: props.text }}>
            </MessageTextStyled>}
        </VideoMessageStyled>
    )
}

export default VideoMessage;

const VideoMessageStyled = styled.div`
display: flex;
flex-direction: column;
`

interface MessageTextStyledProps {
    $last: boolean,
    $width?: number
}

const MessageTextStyled = styled.span<MessageTextStyledProps>`
background-color: var(--background-color);
padding: 8px 16px 12px 16px;
border-bottom-left-radius: ${props => props.$last ? "0" : "8px"};
border-bottom-right-radius: ${props => props.$last ? "0" : "8px"};
${props => props.$width ? `width: ${props.$width}px;` : ""}

>a {
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
`