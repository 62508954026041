import React from 'react';
import { usePrevious } from '../../services/utils';

interface SlideSwitchProps {
    direction: "left-to-right" | "right-to-left";
    thumb: React.ReactNode;
    track: React.ReactNode;
    onSlideEnd?: () => void;
};

/**
 * Что-то похожее на "Slide to unlock" в старых iOS:
 * Слайдер, который требует осознанного перемещения ползунка до конца трека, чтобы вызвать колбек завершения. 
 */
const SlideSwitch: React.FC<SlideSwitchProps> = (props) => {

    const { thumb, track, direction, onSlideEnd } = props;

    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const thumbRef = React.useRef<HTMLDivElement | null>(null);
    const trackRef = React.useRef<HTMLDivElement | null>(null);
    const animationRef = React.useRef<number | null>(null);

    const [containerRect, setContainerRect] = React.useState<DOMRect | null>(null);
    const [thumbRect, setThumbRect] = React.useState<DOMRect | null>(null);
    const [trackRect, setTrackRect] = React.useState<DOMRect | null>(null);

    const trackPrev = usePrevious(track);
    const thumbPrev = usePrevious(thumb);

    React.useLayoutEffect(
        () => {
            function onResize() {
                if (containerRef.current !== null) {
                    const rect = containerRef.current.getBoundingClientRect();
                    setContainerRect(rect);
                }
                else {
                    setContainerRect(null);
                }

                if (thumbRef.current !== null) {
                    const rect = thumbRef.current.getBoundingClientRect();
                    setThumbRect(rect);
                }
                else {
                    setThumbRect(null);
                }

                if (trackRef.current !== null) {
                    const rect = trackRef.current.getBoundingClientRect();
                    setTrackRect(rect);
                }
                else {
                    setTrackRect(null);
                }
            }

            if (thumbPrev !== thumb || trackPrev !== track) {
                onResize();
            }

            onResize();
            window.addEventListener("resize", onResize);
            return () => {
                window.removeEventListener("resize", onResize);
            };
        },
        [thumb, thumbPrev, track, trackPrev]
    );

    const maxValue = 100;
    const speed = 0.1;

    const [value, setValue] = React.useState<number>(0);

    const animateHandler = React.useCallback(
        () => {
            setValue((x) => {
                if (x > 0) {
                    animationRef.current = window.requestAnimationFrame(animateHandler);
                }

                if (x >= maxValue) {
                    !!onSlideEnd && onSlideEnd();
                }

                const newValue = x - (speed * maxValue);

                if (newValue < 0) {
                    return 0;
                }

                return newValue;
            });
        },
        [onSlideEnd]
    );

    const handleTouchStart = React.useCallback(
        (e: React.MouseEvent | React.TouchEvent) => {
            if (animationRef.current !== null) {
                window.cancelAnimationFrame(animationRef.current);
            }

            // Получение точки касания в разных типах событий
            function getClientX(e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent): number {
                if (e instanceof MouseEvent) {
                    return e.clientX;
                }
                else if (e instanceof TouchEvent) {
                    return e.touches[0].clientX;
                }
                else if (e.nativeEvent instanceof MouseEvent) {
                    return e.nativeEvent.clientX;
                }
                else if (e.nativeEvent instanceof TouchEvent) {
                    return e.nativeEvent.touches[0].clientX;
                }
                else {
                    throw new Error(`Not implemented for ${e.type} event`);
                }
            }

            // Получение расстояния хитбокса от края родительского блока
            function getStartMargin(rect: DOMRect) {
                switch (direction) {
                    case "left-to-right":
                        return rect.left;
                    case "right-to-left":
                        return rect.right;
                    default:
                        throw new Error(`Not implemented for ${direction}`);
                }
            }

            if (thumbRect === null) {
                return;
            }

            const clientX = getClientX(e);

            // Позиция внутри переммещаемого элемента в момент касания
            const thumbInnerOffsetX = clientX - getStartMargin(thumbRect);

            const handleMove = (e: MouseEvent | TouchEvent) => {
                const clientX = getClientX(e);

                if (containerRef.current && thumbRect !== null) {
                    const containerRect = containerRef.current.getBoundingClientRect();

                    const trackWidth = containerRect.width - thumbRect.width;

                    if (trackWidth === 0) {
                        return;
                    }

                    let newValueRaw = ((clientX - getStartMargin(containerRect) - thumbInnerOffsetX) / trackWidth) * maxValue;

                    if (direction === "right-to-left") {
                        newValueRaw *= -1;
                    }

                    const newValue = Math.min(maxValue, Math.max(0, newValueRaw));
                    setValue(newValue);
                }
            };

            const handleEnd = () => {
                animationRef.current = window.requestAnimationFrame(animateHandler);

                document.removeEventListener('mousemove', handleMove);
                document.removeEventListener('mouseup', handleEnd);
                document.removeEventListener('touchmove', handleMove);
                document.removeEventListener('touchend', handleEnd);
            };

            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleEnd);
            document.addEventListener('touchmove', handleMove);
            document.addEventListener('touchend', handleEnd);
        },
        [thumbRect, direction, animateHandler]
    );

    const offsetX = React.useMemo<number>(
        () => {
            if (containerRect === null || thumbRect === null) {
                return 0;
            }

            const trackWidth = containerRect.width - thumbRect.width;

            if (trackWidth === 0) {
                return 0;
            }

            return (value / maxValue) * trackWidth;
        },
        [containerRect, thumbRect, value]
    );

    return (
        <div
            ref={containerRef}
            style={{
                position: "relative",
                height: thumbRect !== null ? thumbRect.height : 0,
            }}
        >
            <div>
                {track}
            </div>
            <div
                ref={thumbRef}
                style={{
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: direction === "left-to-right" ? `${offsetX}px` : undefined,
                    right: direction === "right-to-left" ? `${offsetX}px` : undefined,
                }}
                onMouseDown={handleTouchStart}
                onTouchStart={handleTouchStart}
            >
                {thumb}
            </div>
        </div>
    );
};

export default SlideSwitch;