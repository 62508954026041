import * as React from 'react';
import { styled } from 'styled-components';
import puzzleImage from '../../public/puzzle.svg'
import { makeRedirect, sanitizerConfig } from '../../services/utils';
import DOMPurify from 'dompurify';

export interface SurveyClosedFinalScreenProps {
  message: string,
  redirectUrl?: string | undefined;
  redirectMessage?: string | undefined;
}

const SurveyClosedFinalScreen = (props: SurveyClosedFinalScreenProps) => {

  const [counter, setCounter] = React.useState(5);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!!props.redirectUrl) {
      if (counter > 0) {
        timeoutId = setTimeout(() => setCounter(x => x - 1), 1000);
      } else {
        makeRedirect(props.redirectUrl, 0);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [counter, props.redirectUrl]);

  const message = React.useMemo(
    () => DOMPurify.sanitize(props.message, sanitizerConfig),
    [props.message]
  );

  const redirectMessageTemplate = React.useMemo(
    () => !!props.redirectMessage
      ? DOMPurify.sanitize(props.redirectMessage, sanitizerConfig)
      : null,
    [props.redirectMessage]
  );

  const redirectMessage = React.useMemo(
    () => redirectMessageTemplate !== null
      ? redirectMessageTemplate.replace(/<Countdown.*\/>/gim, `${counter}`)
      : null,
    [redirectMessageTemplate, counter]
  );

  return (
    <FinalScreenContainerStyled>
      <FinalScreenImageStyled $image={puzzleImage} />
      <FinalScreenTextStyled
        dir="auto"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {redirectMessage !== null && (
        <FinalScreenTextStyled
          dangerouslySetInnerHTML={{ __html: redirectMessage }}
        />
      )}
    </FinalScreenContainerStyled >
  );
}

const FinalScreenContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
`

interface FinalScreenImageStyledProps {
  $image: string;
}

const FinalScreenImageStyled = styled.div<FinalScreenImageStyledProps>`
  background-image: url(${props => props.$image});
  background-size: cover;
  font-size: 60%;
  @media (max-width: 800px) {
    font-size: 50%;
  };
  height: 30em;
  width: 22em;
`

const FinalScreenTextStyled = styled.div`
  margin: 0 1em;
`

export default SurveyClosedFinalScreen