import React from "react";
import useMediaRecorderOptions from "./useMediaRecorderOptions";

type AudioRecordingStatus = "idle" | "recording";

interface AudioRecorder {
    startRecording: (stream: MediaStream, stopSignal: AbortSignal, cancelSignal: AbortSignal) => Promise<AudioRecordResult>;
    timeElapsed: number;
    recordingState: AudioRecordingStatus;
    isRecording: boolean;
}

interface AudioRecordResult {
    url: string;
    file: File;
    blob: Blob;
}

function useAudioRecorder(): AudioRecorder {

    const [recordingState, setRecordingState] = React.useState<AudioRecordingStatus>('idle');
    const [time, setTime] = React.useState<number>(0);
    const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder | null>(null);

    const mediaRecorderOptions = useMediaRecorderOptions();

    React.useEffect(
        () => {
            if (recordingState === "idle") {
                setTime(0);
                return;
            }

            const step = 100;

            function handleTick() {
                setTime(x => x + (step / 1000));
            }

            const intervalId = window.setInterval(handleTick, step);

            return () => {
                window.clearInterval(intervalId);
            };
        },
        [recordingState]
    );

    React.useEffect(
        () => {
            if (mediaRecorder === null) {
                return;
            }

            function handleStart() {
                setRecordingState("recording");
            }

            function handleStop() {
                setRecordingState("idle");
            }

            mediaRecorder.addEventListener("start", handleStart);
            mediaRecorder.addEventListener("stop", handleStop);

            return () => {
                mediaRecorder.removeEventListener("start", handleStart);
                mediaRecorder.removeEventListener("stop", handleStop);
            }
        },
        [mediaRecorder]
    );

    const startRecording = React.useCallback(
        (stream: MediaStream, stopSignal: AbortSignal, cancelSignal: AbortSignal): Promise<AudioRecordResult> => {
            return new Promise<AudioRecordResult>((resolve, reject) => {
                if (mediaRecorderOptions === null) {
                    reject(new Error("No options for MediaRecorder"));
                    return;
                }

                const mediaRecorder = new MediaRecorder(stream, mediaRecorderOptions);
                const chunks: BlobPart[] = [];

                stopSignal.onabort = () => {
                    mediaRecorder.stop();
                };

                cancelSignal.onabort = () => {
                    mediaRecorder.stop();
                };

                mediaRecorder.addEventListener("dataavailable", (e) => {
                    chunks.push(e.data);
                })

                mediaRecorder.addEventListener("stop", () => {
                    if (cancelSignal.aborted) {
                        reject("MediaRecorder was aborted");
                        setMediaRecorder(null);
                        return;
                    }

                    const audioBlob = new Blob(chunks, { type: mediaRecorderOptions.mimeType });
                    const audioUrl = URL.createObjectURL(audioBlob);

                    const audioFile = new File([audioBlob], `voice.${mediaRecorderOptions.fileExtension}`, {
                        type: mediaRecorderOptions.mimeType,
                    });

                    setMediaRecorder(null);
                    resolve({
                        url: audioUrl,
                        blob: audioBlob,
                        file: audioFile,
                    });
                });

                mediaRecorder.start();

                setMediaRecorder(x => {
                    if (x !== null) {
                        x.stop();
                    }

                    return mediaRecorder;
                });
            });
        },
        [mediaRecorderOptions]
    );

    // Завершаем запись при размонтировании
    React.useEffect(
        () => {
            return () => {
                if (mediaRecorder !== null) {
                    mediaRecorder.stop();
                }
            };
        },
        [mediaRecorder]
    );

    const result = React.useMemo<AudioRecorder>(
        () => ({
            startRecording,
            timeElapsed: time,
            recordingState,
            isRecording: recordingState === 'recording',
        }),
        [recordingState, startRecording, time]
    );

    return result;
}

export default useAudioRecorder;
