import React from "react";
import { Message } from "./Message";
import { HubMessageText } from "../apiClient";
import { DebugMessage } from "./DebugMessage";

export class MessagesContextProps {
    public messages: Message[] = [];
    public isModeratorTyping: boolean = false;
    public debugMessages: Map<number, DebugMessage[]> = new Map<number, DebugMessage[]>();
    public isAudioMessageProcessing: boolean = false;
    public isAudioMessageRecording: boolean = false;

    enqueueMessageAsync: (msg: Message) => Promise<void> = (msg) => Promise.resolve()
    addMessageInstantAsync: (msg: Message) => Promise<number> = (msg) => Promise.resolve(0)
    setIsModeratorTypingAsync: (isTyping: boolean) => Promise<void> = (isTyping) => Promise.resolve()
    addReaction: (userMessageSentId: number, reaction: string) => Promise<void> = (userMessageSentId, reaction) => Promise.resolve()
    syncMessage: (clientMessageId: number, message: HubMessageText) => Promise<void> = (clientMessageId, message) => Promise.resolve()
    clear: () => Promise<void> = () => Promise.resolve()
    scrollToBottom: () => void = () => { }
    addDebugMessage: (newMessage: DebugMessage) => Promise<void> = (newMessage) => Promise.resolve()
    getDebugMessages: (targetMessageId: number) => DebugMessage[] = (targetMessageId) => []
    getMessagesWithQueued: () => Message[] = () => []
    toggleIsAudioMessageProcessing: (isProcessing: boolean) => Promise<void> = (isProcessing) => Promise.resolve()
    toggleIsAudioMessageRecording: (isRecording: boolean) => Promise<void> = (isRecording) => Promise.resolve()
    addAudioMessageResultAsync: (message: Message) => Promise<void> = (message) => Promise.resolve()

    constructor(messages: Message[]) {
        this.messages = messages;
    }
}

const MessagesContext = React.createContext<MessagesContextProps>(new MessagesContextProps([]));

export default MessagesContext;

export function useMessagesContext(): MessagesContextProps {
    const result = React.useContext(MessagesContext);

    if (!result) {
        throw new Error("You must use this hook within context provider");
    }

    return result;
}
