import React from "react";
import Stack from "../Stack/Stack";
import { DefaultTheme, useTheme } from "styled-components";
import Typography from "../Typography/Typography";
import { SandboxViewStyled } from "./SandboxView.styled";
import {
    IoBugIcon,
    IoPauseIcon,
    IoPlayIcon,
    IoVolumeHighIcon,
    IoVolumeMediumIcon,
    IoVolumeMuteIcon,
    LanguageChangeIcon,
    CookieIcon,
    MessageSendIcon,
    AttachmentIcon,
    VoiceDeleteIcon,
    VoiceRecordIcon,
    VoiceMoveLeftIcon,
    CloseIcon,
    VoicePlayIcon,
    VoicePauseIcon
} from "../Icons/Icons";
import { SvgIconStyledProps } from "../SvgIcon/SvgIcon.styled";
import Button, { ButtonBackgroundColor } from "../Button/Button";
import IconButton, { IconButtonProps } from "../IconButton/IconButton";
import Dialog from "../Dialog/Dialog";
import { DialogBody, DialogFooter, DialogHeader } from "../Dialog/Dialog.styled";

const SandboxView: React.FC = () => {

    const theme = useTheme();

    const [showDialog, toggleShowDialog] = React.useState<boolean>(false);

    const drawTree = React.useCallback(
        (root: object): React.ReactNode => {
            function drawNode(node: object) {
                if (typeof node === "object") {
                    return (
                        <Stack style={{ paddingLeft: "16px" }} $gap={"4px"}>
                            {Object.entries(node).map(([key, value]) => (
                                <Typography key={key}>
                                    - <b>{key}</b> = {drawNode(value)}
                                </Typography>
                            ))}
                        </Stack>
                    );
                }
                else {
                    const color = typeof node === "string" ? node : "#fff";

                    return (
                        <Typography style={{
                            backgroundColor: color,
                            border: "solid 1px #000",
                        }}>
                            <span style={{
                                color: color,
                                filter: "invert(100%)",
                                textShadow: "1px 1px 1px #fff8",
                            }}>
                                {JSON.stringify(node)}
                            </span>
                        </Typography>
                    );
                }
            }

            return drawNode(root);
        },
        []
    );

    const colors = React.useMemo<string[]>(
        () => ([
            "#FF0000",
            "#00FF00",
            "#0000FF",
        ]),
        []
    );

    const sizes = React.useMemo<(keyof DefaultTheme["typography"])[]>(
        () => ([
            "h1",
            "h2",
            "subtitle1",
            "body1",
            "caption",
        ]),
        []
    );

    const buttonSizes = React.useMemo<IconButtonProps["$size"][]>(
        () => ([
            "small",
            "meduim",
            "large",
        ]),
        []
    );

    const icons = React.useMemo<((size: SvgIconStyledProps["$fontSize"], color: string) => React.ReactNode)[]>(
        () => ([
            (size, color) => <MessageSendIcon $color={color} $fontSize={size} />,
            (size, color) => <AttachmentIcon $color={color} $fontSize={size} />,
            (size, color) => <VoiceDeleteIcon $color={color} $fontSize={size} />,
            (size, color) => <VoiceRecordIcon $color={color} $fontSize={size} />,
            (size, color) => <VoiceMoveLeftIcon $color={color} $fontSize={size} />,
            (size, color) => <CloseIcon $color={color} $fontSize={size} />,
            (size, color) => <VoicePlayIcon $color={color} $fontSize={size} />,
            (size, color) => <VoicePauseIcon $color={color} $fontSize={size} />,
            // Legacy
            (size, color) => <IoBugIcon $color={color} $fontSize={size} />,
            (size, color) => <IoPauseIcon $color={color} $fontSize={size} />,
            (size, color) => <IoPlayIcon $color={color} $fontSize={size} />,
            (size, color) => <IoVolumeHighIcon $color={color} $fontSize={size} />,
            (size, color) => <IoVolumeMediumIcon $color={color} $fontSize={size} />,
            (size, color) => <IoVolumeMuteIcon $color={color} $fontSize={size} />,
            (size, color) => <LanguageChangeIcon $color={color} $fontSize={size} />,
            (size, color) => <CookieIcon $color={color} $fontSize={size} />,
        ]),
        []
    );

    return (
        <SandboxViewStyled>
            <Stack $gap={theme.spaces.space30}>
                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">Typography</Typography>
                    <div style={{ display: "grid", gridAutoFlow: "column", gap: "10px" }}>
                        <Stack>
                            <Typography $variant="h1">H1</Typography>
                            <Typography $variant="h2">H2</Typography>
                            <Typography $variant="h3">H3</Typography>
                        </Stack>
                        <Stack>
                            <Typography $variant="body1">Body1</Typography>
                            <Typography $variant="body2">Body2</Typography>
                            <Typography $variant="button">Button</Typography>
                            <Typography $variant="caption">Caption</Typography>
                            <Typography $variant="overline">Overline</Typography>
                            <Typography $variant="subtitle1">Subtitle1</Typography>
                            <Typography $variant="subtitle2">Subtitle2</Typography>
                        </Stack>
                    </div>
                    <Stack>
                        <Typography>ABCČDĎEFGHIJKLMNOPQRŘSŠTŤUVWXYZŽ</Typography>
                        <Typography>ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ</Typography>
                        <Typography>ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ</Typography>
                        <Typography>АБВГДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ</Typography>
                        <Typography>АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҰҮФХҺЦЧШЩЫІЭЮЯ</Typography>
                        <Typography>爱学大小朋友水火天地人明山日月书车学生国家时间</Typography>
                        <Typography>ԱԲԳԴԵԶԷԸԹԺԻԼԽԾԿՀԾՋՌՍՎՏՐՓՔՕՖ</Typography>
                        <Typography>กขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ</Typography>
                        <Typography>אבגדהוזחטיכלמנסעפצקרשת</Typography>
                        <Typography>ابتثجحخدذرزسشصضطظعغفقكلمنهوي</Typography>
                    </Stack>
                </Stack>

                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">Button</Typography>
                    <table>
                        <tbody>
                            {colors.map(x => (
                                <tr key={x}>
                                    {sizes.map(y => (
                                        <td key={y}>
                                            <Button onClick={() => { }} backgroundColor={ButtonBackgroundColor.lightBlue}>
                                                {y}
                                            </Button>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Stack>
                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">IconButton</Typography>
                    <table>
                        <tbody>
                            {colors.map((x, i) => (
                                <tr key={i}>
                                    {buttonSizes.map((y, j) => (
                                        <td key={y}>
                                            <IconButton $size={y} $color={x} disabled={i === j}>
                                                <MessageSendIcon $color={"#FFFFFF"} />
                                            </IconButton>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Stack>

                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">Icon</Typography>
                    <table>
                        <thead>
                            <tr>
                                {sizes.map(y => (
                                    <th key={y}>
                                        <Typography $variant={y}>{y}</Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {icons.map((x, i) => (
                                <tr key={i}>
                                    {sizes.map(y => (
                                        <td key={y}>
                                            {x(y, colors[i % colors.length])}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Stack>

                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">Dialogs</Typography>
                    <div>
                        <Button
                            onClick={() => toggleShowDialog(true)}
                            backgroundColor={ButtonBackgroundColor.lightBlue}
                        >
                            Show dialog
                        </Button>
                    </div>
                    <Dialog open={showDialog} onClose={() => toggleShowDialog(false)}>
                        <DialogHeader>
                            Microphone access denied
                        </DialogHeader>
                        <DialogBody>
                            Allow microphone access in the browser settings.
                            Only with this permission will you be able to record voice messages.
                        </DialogBody>
                        <DialogFooter>
                            <div>
                                <Button
                                    onClick={() => toggleShowDialog(false)}
                                    backgroundColor={ButtonBackgroundColor.lightBlue}
                                >
                                    Allow
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={() => toggleShowDialog(false)}
                                    backgroundColor={ButtonBackgroundColor.white}
                                >
                                    Deny
                                </Button>
                            </div>
                        </DialogFooter>
                    </Dialog>
                </Stack>

                <Stack $gap={theme.spaces.space20}>
                    <Typography $variant="h1">Palette</Typography>
                    {drawTree(theme.palette)}
                </Stack>
            </Stack>
        </SandboxViewStyled>
    );
};

export default SandboxView;