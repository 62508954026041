import SvgIcon, { SvgIconProps } from '../SvgIcon/SvgIcon';

// Сторонние иконки, которые не соответствуют макету (их рано или поздно предстоит удалить)
import { ReactComponent as IoBug } from '../../assets/IoBug.svg';
import { ReactComponent as IoPause } from '../../assets/IoPause.svg';
import { ReactComponent as IoPlay } from '../../assets/IoPlay.svg';
import { ReactComponent as IoScan } from '../../assets/IoScan.svg'
import { ReactComponent as IoVolumeHigh } from '../../assets/IoVolumeHigh.svg';
import { ReactComponent as IoVolumeLow } from '../../assets/IoVolumeLow.svg';
import { ReactComponent as IoVolumeMedium } from '../../assets/IoVolumeMedium.svg';
import { ReactComponent as IoVolumeMute } from '../../assets/IoVolumeMute.svg';
import { ReactComponent as LanguageChange } from '../../assets/LanguageChangeIcon.svg';
import { ReactComponent as Cookie } from '../../assets/Cookie.svg';

// Каноничные иконки из макета с редизайном
import { ReactComponent as MessageSend } from '../../assets/img/message-send.svg';
import { ReactComponent as Attachment } from '../../assets/img/attachment.svg';
import { ReactComponent as VoiceDelete } from '../../assets/img/voice-delete.svg';
import { ReactComponent as VoiceRecord } from '../../assets/img/voice-record.svg';
import { ReactComponent as VoiceMoveLeft } from '../../assets/img/voice-move-left.svg';
import { ReactComponent as Close } from '../../assets/img/close.svg';
import { ReactComponent as VoicePlay } from '../../assets/img/voice-play.svg';
import { ReactComponent as VoicePause } from '../../assets/img/voice-pause.svg';


export const IoBugIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoBug /></SvgIcon>;
export const IoPauseIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoPause /></SvgIcon>;
export const IoPlayIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoPlay /></SvgIcon>;
export const IoScanIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoScan /></SvgIcon>;
export const IoVolumeHighIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoVolumeHigh /></SvgIcon>;
export const IoVolumeLowIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoVolumeLow /></SvgIcon>;
export const IoVolumeMediumIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoVolumeMedium /></SvgIcon>;
export const IoVolumeMuteIcon = (props: SvgIconProps) => <SvgIcon {...props}><IoVolumeMute /></SvgIcon>;

export const LanguageChangeIcon = (props: SvgIconProps) => <SvgIcon {...props}><LanguageChange /></SvgIcon>;
export const CookieIcon = (props: SvgIconProps) => <SvgIcon {...props}><Cookie /></SvgIcon>;
export const MessageSendIcon = (props: SvgIconProps) => <SvgIcon {...props}><MessageSend /></SvgIcon>;
export const AttachmentIcon = (props: SvgIconProps) => <SvgIcon {...props}><Attachment /></SvgIcon>;
export const VoiceDeleteIcon = (props: SvgIconProps) => <SvgIcon {...props}><VoiceDelete /></SvgIcon>;
export const VoiceRecordIcon = (props: SvgIconProps) => <SvgIcon {...props}><VoiceRecord /></SvgIcon>;
export const VoiceMoveLeftIcon = (props: SvgIconProps) => <SvgIcon {...props}><VoiceMoveLeft /></SvgIcon>;
export const CloseIcon = (props: SvgIconProps) => <SvgIcon {...props}><Close /></SvgIcon>;
export const VoicePlayIcon = (props: SvgIconProps) => <SvgIcon {...props}><VoicePlay /></SvgIcon>;
export const VoicePauseIcon = (props: SvgIconProps) => <SvgIcon {...props}><VoicePause /></SvgIcon>;
