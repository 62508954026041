import React from "react";

function useAudioLevel(stream: MediaStream | null) {

    const context = React.useMemo(
        () => {
            if (!stream) {
                return null;
            }

            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(stream);

            analyser.smoothingTimeConstant = 0.5;
            analyser.fftSize = 512;

            source.connect(analyser);

            return {
                audioContext,
                analyser,
                source,
            };
        },
        [stream]
    );

    React.useEffect(
        () => {
            return () => {
                if (!!context) {
                    context.source.disconnect();
                    context.analyser.disconnect();
                    context.audioContext.close();
                }
            }
        },
        [context]
    );

    const getAudioLevel = React.useCallback(
        (): number | null => {
            if (!context) {
                return null;
            }

            const volumes = new Uint8Array(context.analyser.frequencyBinCount);
            context.analyser.getByteFrequencyData(volumes);

            const total = volumes.reduce((a, b) => a + b, 0);
            const average = total / volumes.length;

            return average / 255;
        },
        [context]
    );

    const result = React.useMemo(
        () => ({
            getAudioLevel,
        }),
        [getAudioLevel]
    );

    return result;
}

export default useAudioLevel;
