import ChatApp from './components/ChatRouter';
import MessagesContextProvider from './providers/MessagesProvider';
import styled, { ThemeProvider } from 'styled-components';
import React, { useMemo, useState } from 'react';
import { init as SentryInit } from "@sentry/react"
import { BrowserTracing } from "@sentry/browser";
import ImageExpandProvider from './providers/ImageExpandProvider';
import { ENVIRONMENT, REGION, SENTRY_URL } from './services/utils';
import CookieAgreementPopup from './components/CookieAgreementPopup';
import ChatStateProvider from './providers/ChatStateProvider';
import MessageWrapperRefProvider from './providers/MessageWrapperRefProvider';
import Spinner from './components/Spinner';
import { useMainTheme } from './themes/mainTheme';
import SandboxView from './components/SandboxView/SandboxView';

SentryInit({
    dsn: `https://029daeb2b42247169312ade8a46e71db@${SENTRY_URL}//17`,
    integrations: [new BrowserTracing()],
    environment: `${ENVIRONMENT} ${REGION}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    enabled: ENVIRONMENT !== "development",
});

const App: React.FC = () => {

    const href = new URL(window.location.href);
    const params = href.searchParams;

    // TODO: state здесь избыточен - его нужно заменить на useMemo, а вместо пустой стороки сделать null, 
    // TODO: чтобы не было путаницы пустого параметра и непереданного параметра
    const [querySearch] = useState<string>(href.search);
    const [linkInvitation] = useState<string>(params.get('i') || '');
    const [interviewUid] = useState<string>(params.get('u') || '');
    const [interviewExtId] = useState<string>(params.get('e') || '');
    const [stimulusCellUid] = useState<string>(params.get('c') || '');
    const [language] = useState<string>(params.get('l') || '');

    const isSandbox = React.useMemo(() => params.has('sandbox'), [params]);

    const [cookieAgreementPopupOpen, toggleCookieAgreementPopupOpen] = useState<boolean>(localStorage.getItem('cookie-agreed') === null);

    const showCookieWall = useMemo(
        () => cookieAgreementPopupOpen && REGION !== "RU",
        [cookieAgreementPopupOpen]
    );

    // TODO: перисать на хуки
    if ("virtualKeyboard" in navigator) {
        let keyboardNavigator: KeyboardNavigatorType = navigator.virtualKeyboard as KeyboardNavigatorType;
        keyboardNavigator.overlaysContent = true;
        keyboardNavigator.ongeometrychange = () => {
            document.getElementsByTagName("html")[0].style.height = `calc(100% - ${keyboardNavigator.boundingRect.height}px)`;
        }
    }

    const theme = useMainTheme("light");

    if (isSandbox) {
        return (
            <ThemeProvider theme={theme}>
                <SandboxView />
            </ThemeProvider>
        );
    }

    if (showCookieWall) {
        return (
            <ThemeProvider theme={theme}>
                <Spinner />
                <CookieAgreementPopup
                    onClose={() => { toggleCookieAgreementPopupOpen(false); }}
                    language={localStorage.getItem("forced_language") || language || navigator.language.split('-')[0]}
                />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <AppStyled>
                <ChatStateProvider interviewUid={interviewUid}>
                    <MessageWrapperRefProvider>
                        <MessagesContextProvider messages={[]}>
                            <ImageExpandProvider>
                                <ChatApp
                                    linkInvitation={linkInvitation}
                                    interviewUid={interviewUid}
                                    interviewExtId={interviewExtId}
                                    querySearch={querySearch}
                                    stimulusCellUid={stimulusCellUid}
                                    language={language}
                                />
                                <ModalStyled id="modal-root" />
                            </ImageExpandProvider>
                        </MessagesContextProvider>
                    </MessageWrapperRefProvider>
                </ChatStateProvider>
            </AppStyled>
        </ThemeProvider>
    );
}

interface KeyboardNavigatorType {
    overlaysContent: boolean,
    ongeometrychange: () => void,
    boundingRect: DOMRect
}

let AppStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const ModalStyled = styled.div`
  position: relative;
  z-index: 20;

  & .modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

App.whyDidYouRender = true;

export default App;
