import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { computePosition, flip } from '@floating-ui/dom';
import { LanguageChangeIcon } from './Icons/Icons';
import { useTouchScreen } from "../services/utils";

export interface DropdownProps {
    options: DropdownOption[];
    selectedOptionCode: string;
    disabled?: boolean;
}

export interface DropdownOption {
    name: string;
    code: string;
    fn: () => void;
}

const Dropdown = (props: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const selectRef = useRef<HTMLDivElement>(null);
    const optionsRefList = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (optionsRefList.current && selectRef.current) {
            computePosition(selectRef.current, optionsRefList.current, {
                middleware: [flip()]
            }).then(({ x, y }) => {
                if (!optionsRefList.current)
                    return;

                optionsRefList.current.style.top = `${y}px`;
                optionsRefList.current.style.left = `${x}px`;
            })
        }
    }, [isOpen])

    const toggleDropdown = (newState: boolean) => {
        if (props.disabled) return;
        setIsOpen(newState);
    };

    const isMobile = useTouchScreen();

    const findOptionByCode = (codeToFind: string) => {
        return props.options.find(x => x.code.toLowerCase() === codeToFind.toLowerCase())
    }

    const selectOnChangeHandler = (selectedCode: string) => {
        const option = findOptionByCode(selectedCode);
        option?.fn();
    }

    const mobileSelect = (<>
        <DropdownMenuSelectStyled $isOpen={isOpen} onChange={(e: ChangeEvent<HTMLSelectElement>) => selectOnChangeHandler(e.target?.value)}>
            {props.options.map((x, index) =>
                <option
                    key={index}
                    data-lang={x.code}
                    value={x.code}
                >
                    {x.name}
                </option>
            )}
        </DropdownMenuSelectStyled>
    </>)

    let dropdownContent = <></>
    if (isOpen) {
        dropdownContent = (
            <DropdownMenuStyled ref={optionsRefList}>
                {props.options.map((x, index) =>
                    <DropdownMenuItemStyled
                        key={index}
                        data-lang={x.code}
                        onClick={x.fn}>{x.name}</DropdownMenuItemStyled>)}
            </DropdownMenuStyled>
        )
    }

    return (
        <>
            <DropdownContainerStyled ref={selectRef} disabled={!!props.disabled}>
                <DropdownToggleStyled onClick={() => {
                    toggleDropdown(!isOpen)
                }}>
                    <IconDropdownToggleContainerStyled>
                        <IconContainerStyled>
                            <LanguageChangeIcon $fontSize="h1" />
                        </IconContainerStyled>
                        <LanguageNameTextStyled>{findOptionByCode(props.selectedOptionCode)?.code}</LanguageNameTextStyled>
                    </IconDropdownToggleContainerStyled>
                    <ArrowIconStyled $isOpen={isOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black">
                            <path d="M12 2 L22 22 L2 22 Z" />
                        </svg>
                    </ArrowIconStyled>
                </DropdownToggleStyled>
                {isMobile && mobileSelect}
            </DropdownContainerStyled>
            {!isMobile && dropdownContent}
            {isOpen && !isMobile && <BackdropStyled className="backdrop" onClick={() => { setIsOpen(false) }} />}
        </>
    );
};

export default Dropdown

const LanguageNameTextStyled = styled.span`
    display: flex;
`

const BackdropStyled = styled.div`
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:1;
`

const IconContainerStyled = styled.div`
    height: 40px;
    width: 40px;
`;

const IconDropdownToggleContainerStyled = styled.div`
    display: inline-flex;
    align-items: center;
`;

const DropdownContainerStyled = styled.div<{ disabled: boolean }>`
    display: flex;
    align-self: end;
    position: relative;
    opacity: ${props => props.disabled ? '0.5' : '1'};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    z-index: 2;
`;

const DropdownToggleStyled = styled.div`
    padding: 8px 16px;
    align-items: center;
    color: #333;
    border: none;
    cursor: pointer;
    display: flex;
    user-select: none;
    gap: 0.5rem;
`;


const ArrowIconStyled = styled.div<ArrowIconStyledProps>`
    width: 12px;
    height: 24px;
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    ${(props) => props.$isOpen && "transform: rotate(0deg);"}

    svg {
        height: 50%;
        transform: scaleY(0.5);
    }
`;

interface ArrowIconStyledProps {
    $isOpen: boolean
}

const DropdownMenuSelectStyled = styled.select<{ $isOpen: boolean }>`
    position: absolute;
    opacity: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
`;

const DropdownMenuStyled = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index:2;
`;

const DropdownMenuItemStyled = styled.li`
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;
  
    &:hover {
      background-color: #f1f1f1;
    }
`;
