import styled, { DefaultTheme } from "styled-components";
import { alpha } from "../../../themes/utils";

const baseTextAreaHeight = "50px";

function calcTextAreaPadding(theme: DefaultTheme) {
    return `calc((${baseTextAreaHeight} - ${theme.typography.body1.lineHeight}) / 2)`;
}

export const MessageTextInputContainerStyled = styled.div<{ $dividerLeft: boolean, $dividerRight: boolean }>`
    position: relative;

    &::before {
        content: "";
        display: ${({ $dividerLeft }) => $dividerLeft ? "block" : "none"};
        position: absolute;
        top: ${({ theme }) => calcTextAreaPadding(theme)};
        bottom: ${({ theme }) => calcTextAreaPadding(theme)};
        width: 1px;
        background-color: ${({ theme }) => theme.palette.divider};
        left: 0;
    }

    &::after {
        content: "";
        display: ${({ $dividerRight }) => $dividerRight ? "block" : "none"};
        position: absolute;
        top: ${({ theme }) => calcTextAreaPadding(theme)};
        bottom: ${({ theme }) => calcTextAreaPadding(theme)};
        width: 1px;
        background-color: ${({ theme }) => theme.palette.divider};
        right: 0;
    }
`

export const MessageTextInputTextAreaStyled = styled.textarea.attrs(({ theme }) => ({ style: theme.typography.body1 }))`
    display: flex;
    align-self: flex-start;
    background-color: transparent;
    border: none;
    resize: none;

    width: 100%;
    min-height: ${baseTextAreaHeight};

    line-height: ${({ theme }) => theme.typography.body1.lineHeight};
    
    padding-top: ${({ theme }) => calcTextAreaPadding(theme)};
    padding-bottom: ${({ theme }) => calcTextAreaPadding(theme)};
    padding-left: ${({ theme }) => theme.spaces.space20};
    padding-right: ${({ theme }) => theme.spaces.space20};

    color: ${({ theme }) => theme.palette.text.primary};

    &::placeholder {
        color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
        user-select: none;
    }

    &:disabled {
        opacity: 0.25;
        pointer-events: none;
        cursor: default;
    }
`

