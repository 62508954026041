import styled from "styled-components";
import { maxWidth } from "../../../themes/utils";

export const MessageVoiceInputContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-radius: ${props => props.theme.shape.borderRadius};
    gap: ${props => props.theme.spaces.space15};
    padding-right: ${props => props.theme.spaces.space20};
    padding-left: ${props => props.theme.spaces.space20};
    background-color: ${props => props.theme.palette.special.voiceInputBackground};

    @media ${({ theme }) => maxWidth(theme.breakpoints.tablet)} {
        height: 40px;
    }
`

export const MessageVoiceInputStatusStyled = styled.div<{ $isActive: boolean }>`
    width: 14px;
    height: 14px;
    background-color: ${props => props.theme.palette.status.success};
    border-radius: 50%;
    border: solid 2px ${props => props.theme.palette.common.white};
    box-shadow: 0px 1px 3px #0004;

    animation-name: ${props => props.$isActive ? "blink" : "none"};
    animation-duration: 0.75s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes blink {
        from {
            opacity: 1.0;
        }
        to {
            opacity: 0.5;
        }
    }
`

export const MessageVoiceInputTimelineStyled = styled.div`
    flex-grow: 1;
    position: relative;
    height: 25px;
    overflow: hidden;
`

export const MessageVoiceInputTimelineItemStyled = styled.div<{ $index: number }>`
    position: absolute;
    background-color: ${props => props.theme.palette.secondary.main};
    width: 2px;
    height: 2px;
    right: ${({ $index }) => $index * 4}px;
    border-radius: 2px;
    top: 50%;
    transform: translateY(-50%);
`
