import styled from "styled-components";

export const GreetingFooterStyled = styled.footer`
    display: flex;
    flex: 1;
    align-items: center;
`

export const GreetingMessageBubbleTextStyled = styled.span`
    text-align: start;
    white-space: pre-wrap;
    width: fit-content;
`

export const GreetingMessageBubbleStyled = styled.div<{ $hidden: boolean }>`
    display: flex;
    justify-content: end;
    flex-direction: column;
    white-space: pre-wrap;
    --background-color: #F2F2F7;
    --color: #2C2C2E;
    text-align: start;
    width: fit-content;
    ${props => props.$hidden ? "visibility: hidden;" : ""}
`

export const GreetingAvatarNameStyled = styled.span`
    color: #2C2C2E;
    opacity: 0.4;
`

export const GreetingWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 2;
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin: auto;
    scroll-behavior: smooth;

    @media (max-width: 767px) {
        overflow: auto;
    }
`

export const GreetingBlockStyled = styled.div<{ $hide?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    ${props => props.$hide ? "visibility: hidden;" : ""}

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 8px;
    }
`

export const GreetingColumnBlockStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 66%;
    gap: 20px;
    position: relative;

    @media (max-width: 767px) {
        gap: 12px;
    }

    padding-left: 0px;
    padding-right: 0px;

    @media (max-width: 767px) {
        padding-left: 12px;
        padding-right: 12px;
    }
`

export const GreetingAvatarStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    justify-content: start;
    align-items: center;

    @media (max-width: 767px) {
        width: 66%;
        align-self: center;
        align-items: center;
        max-width: 300px;
    }
`