import React from "react";
import { InputAreaContainerInactiveStyled, InputAreaContainerStyled, InputAreaInputStyled, InputAreaMainButton, InputAreaTypographyInactiveStyled, InputAreaVoiceOnlyInputContainerStyled, InputAreaVoiceOnlyContainerStyled } from "./InputArea.styled";
import MessageVoiceInput from "./MessageVoiceInput/MessageVoiceInput";
import IconButton, { IconButtonProps } from "../IconButton/IconButton";
import { CloseIcon, MessageSendIcon, VoiceDeleteIcon, VoiceMoveLeftIcon, VoiceRecordIcon } from "../Icons/Icons";
import Stack from "../Stack/Stack";
import { useTheme } from "styled-components";
import SlideSwitch from "../SlideSwitch/SlideSwitch";
import Typography from "../Typography/Typography";
import MessageTextInput from "./MessageTextInput/MessageTextInput";
import { useMessagesContext } from "../../models/MessagesContext";
import useAudioRecorder from "../../services/Audio/useAudioRecorder";
import { BaseMessageRequest, MessageSide, TextMessageRequest, VoiceMessageRequest } from "../../models/Message";
import useAudioLevel from "../../services/Audio/useAudioLevel";
import { InterviewStatus } from "../../apiClient";
import { ConnectionState } from "../../models/ConnectionStateEnum";
import { SvgIconProps } from "../SvgIcon/SvgIcon";
import { alpha } from "../../themes/utils";
import Dialog from "../Dialog/Dialog";
import { DialogBody, DialogFooter, DialogHeader } from "../Dialog/Dialog.styled";
import Button, { ButtonBackgroundColor } from "../Button/Button";
import * as Sentry from "@sentry/react";
import { ENVIRONMENT } from "../../services/utils";

interface InputAreaProps {
    allowedInputTypes: InputType[];
    onMessageSent: (message: BaseMessageRequest) => void;
    onMessageAggregateSent: (messageId: number, isStartDown: boolean) => void;
    disabled: boolean;
    interviewStatus: InterviewStatus;
    enabledText: string;
    disabledText: string;
    conversationEndText: string;
    connectionState: ConnectionState;
}

export enum InputType {
    Text = 1,
    Voice = 2,
    Image = 3,
    Video = 4,
}

export enum InputAreaMode {
    None = 0,
    Text = 1,
    Voice = 2,
}

const InputArea: React.FC<InputAreaProps> = (props) => {

    const theme = useTheme();

    const { messages } = useMessagesContext();

    const [audioStream, setAudioStream] = React.useState<MediaStream | null>(null);
    const { startRecording, timeElapsed, isRecording } = useAudioRecorder();
    const { getAudioLevel } = useAudioLevel(audioStream);

    const [voiceStopController, setVoiceStopController] = React.useState<AbortController | null>(null);
    const [voiceCancelController, setVoiceCancelController] = React.useState<AbortController | null>(null);

    const [mode, setMode] = React.useState<InputAreaMode>(InputAreaMode.None);
    const [text, setText] = React.useState<string>("");

    const [showVoicePermissionDialog, toggleShowVoicePermissionDialog] = React.useState<boolean>(false);

    // Означает, что юзер согласился с нашим намерением использовать микрофон 
    // (это не означает, что в браузере по факту разрешен микрофоны)
    const [isVoicePermitted, toggleVoicePermitted] = React.useState<boolean>(false);

    const [currentAudioLevel, setCurrentAudioLevel] = React.useState<number | null>(null);

    const { allowedInputTypes, disabled, connectionState, onMessageSent, onMessageAggregateSent } = props;

    const allowText = allowedInputTypes.includes(InputType.Text);
    const allowVoice = allowedInputTypes.includes(InputType.Voice);
    const isVoiceOnly = allowVoice && !allowText;

    // Выбор режима работы в случае смены набора допустимых способов ввода
    // (например, если был включен голосовой режим, но вдруг перестал быть доступен -- тогда режим будет переключен на Text)
    React.useEffect(
        () => {
            setMode(x => {
                if (allowText) {
                    return InputAreaMode.Text;
                }

                if (allowVoice) {
                    return InputAreaMode.Voice;
                }

                return InputAreaMode.None;
            });
        },
        [allowText, allowVoice]
    );

    const sendMessage = React.useCallback(
        (message: BaseMessageRequest) => {
            if (disabled || connectionState !== ConnectionState.Connected) {
                return;
            }

            onMessageSent(message);
        },
        [connectionState, disabled, onMessageSent]
    );

    // Делает замер уровня звука раз в 100 ms 
    // (это нужно для анимирования кнопки отправки, которая покачивается в такт звуку)
    React.useEffect(
        () => {
            function onSample() {
                const level = getAudioLevel();
                setCurrentAudioLevel(level);
            }

            const intervalId = window.setInterval(onSample, 100);
            return () => {
                window.clearInterval(intervalId);
                setCurrentAudioLevel(null);
            };
        },
        [getAudioLevel]
    );

    /** Обнуляет аудио-поток 
     * (дополнительно останавливает его, чтобы не занимать микрофон на устройстве) */
    const resetVoiceStream = React.useCallback(
        () => {
            setAudioStream(x => {
                if (!!x) {
                    x.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                return null;
            });
        },
        []
    );

    /** Завершает запись */
    const stopVoiceRecord = React.useCallback(
        () => {
            if (voiceStopController !== null) {
                voiceStopController.abort();
            }

            resetVoiceStream();
        },
        [resetVoiceStream, voiceStopController]
    );

    /** Отменяет процесс записи без ее завершения */
    const cancelVoiceRecord = React.useCallback(
        () => {
            if (voiceCancelController !== null) {
                voiceCancelController.abort();
            }

            resetVoiceStream();
        },
        [resetVoiceStream, voiceCancelController]
    );

    /** Делает переключение на целевой режим, но только в случае, если это допустимо (иначе, оставляет текущий режим) */
    const changeMode = React.useCallback(
        (mode: InputAreaMode) => {
            setMode(x => {
                // На всякий случай обнуляем состояние ввода голоса и текста при фактическом отличии исходного и целевого режима
                if (x !== mode) {
                    setText("");
                    cancelVoiceRecord();
                }

                if (allowText && mode === InputAreaMode.Text) {
                    return mode;
                }

                if (allowVoice && mode === InputAreaMode.Voice) {
                    return mode;
                }

                return x;
            });
        },
        [allowText, allowVoice, cancelVoiceRecord]
    );

    /** Асихнронный метод записи голосового сообщения */
    const startVoiceRecord = React.useCallback(
        async (skipVoicePermissionDialog: boolean = false) => {
            // Показываем диалог, если еще не спрашивали юзера о том, хотел он с нами общаться
            if (!skipVoicePermissionDialog && !isVoicePermitted && ENVIRONMENT !== "production") {
                toggleShowVoicePermissionDialog(true);
                return;
            }

            try {
                changeMode(InputAreaMode.Voice);

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        channelCount: 1,
                        sampleRate: 48_000,
                        noiseSuppression: true,
                        echoCancellation: true,
                        sampleSize: 16,
                    }
                });

                setAudioStream(stream);
                toggleShowVoicePermissionDialog(false);

                const voiceStopController = new AbortController();
                setVoiceStopController(voiceStopController);

                const voiceCancelController = new AbortController();
                setVoiceCancelController(x => {
                    if (x !== null) {
                        x.abort();
                    }

                    return voiceCancelController;
                });

                const result = await startRecording(stream, voiceStopController.signal, voiceCancelController.signal);

                sendMessage(new VoiceMessageRequest(result.file));
            }
            catch (e) {
                if (e instanceof Error) {
                    Sentry.captureException(e, {
                        tags: { from: "startVoiceRecord" },
                    });
                }
            }
            finally {
                toggleShowVoicePermissionDialog(false);
                changeMode(InputAreaMode.Text);
            }
        },
        [changeMode, isVoicePermitted, sendMessage, startRecording]
    );

    React.useEffect(
        () => {
            if (mode !== InputAreaMode.Voice) {
                return;
            }

            if (messages.length === 0) {
                return;
            }

            const lastMessage = messages[messages.length - 1]

            if (lastMessage.side === MessageSide.User && !!lastMessage.id) {
                if (!isRecording) {
                    onMessageAggregateSent(lastMessage.id, true);
                }
                if (audioStream === null && isRecording) {
                    onMessageAggregateSent(lastMessage.id, false);
                }
            }
        },
        [audioStream, isRecording, messages, mode, onMessageAggregateSent]
    );

    const handleInput = React.useCallback(
        (e: React.FormEvent<HTMLTextAreaElement>): void => {
            if (mode !== InputAreaMode.Text) {
                return;
            }

            if (messages.length === 0) {
                return;
            }

            const lastMessage = messages[messages.length - 1]

            if (lastMessage.side === MessageSide.User && !!lastMessage.id) {
                if (e.currentTarget.value === "") {
                    onMessageAggregateSent(lastMessage.id, true);
                }
                if (text === "" && e.currentTarget.value !== "") {
                    onMessageAggregateSent(lastMessage.id, false);
                }
            }
        },
        [messages, mode, onMessageAggregateSent, text]
    );

    const handleEnter = React.useCallback(
        () => {
            if (!!text) {
                sendMessage(new TextMessageRequest(text));
                setText("");
            }
        },
        [sendMessage, text]
    );

    const inputPlaceholderText = React.useMemo(
        () => {
            if (props.disabled) {
                if (props.interviewStatus !== InterviewStatus.Open) {
                    return props.conversationEndText;
                }

                return props.disabledText;
            }
            return props.enabledText;
        },
        [props.conversationEndText, props.disabled, props.disabledText, props.enabledText, props.interviewStatus]
    );

    const mainButton = React.useMemo<React.ReactNode>(
        () => {
            // Дефолтное состояние кнопки
            let iconColor: SvgIconProps["$color"] = theme.palette.text.primary;
            let buttonColor: IconButtonProps["$color"] = theme.palette.background.default;

            let icon = <MessageSendIcon $fontSize="subtitle1" $color={iconColor} />;
            let handleClick: () => void = () => {
                if (!!text) {
                    sendMessage(new TextMessageRequest(text));
                    setText("");
                }
            };

            const isVoiceButton =
                (mode === InputAreaMode.Voice) ||
                (mode === InputAreaMode.Text && allowVoice && text === "");

            if (isVoiceButton) {
                if (!isRecording) {
                    if (isVoiceOnly) {
                        iconColor = theme.palette.secondary.contrastColor;
                        buttonColor = theme.palette.primary;
                    }

                    icon = <VoiceRecordIcon $fontSize="subtitle1" $color={iconColor} />;

                    handleClick = () => {
                        startVoiceRecord();
                    };
                }
                else {
                    iconColor = theme.palette.secondary.contrastColor;
                    buttonColor = theme.palette.secondary;
                    icon = <MessageSendIcon $fontSize="subtitle1" $color={iconColor} />;

                    // Во время записи клик на кнопку приводит к остановке записи и отправке сообщения
                    handleClick = () => {
                        stopVoiceRecord();
                    };
                }
            }

            return (
                <InputAreaMainButton
                    onClick={handleClick}
                    disabled={disabled}
                    $currentAudioLevel={currentAudioLevel}
                    $color={buttonColor}
                >
                    {icon}
                </InputAreaMainButton>
            );
        },
        [allowVoice, currentAudioLevel, disabled, isRecording, isVoiceOnly, mode, sendMessage, startVoiceRecord, stopVoiceRecord, text, theme.palette.background.default, theme.palette.primary, theme.palette.secondary, theme.palette.text.primary]
    );

    const voicePermissionDialog = React.useMemo(
        () => (
            <Dialog open={showVoicePermissionDialog} onClose={() => toggleShowVoicePermissionDialog(false)}>
                <DialogHeader>
                    Microphone access denied
                </DialogHeader>
                <DialogBody>
                    Allow microphone access in the browser settings.
                    Only with this permission will you be able to record voice messages.
                </DialogBody>
                <DialogFooter>
                    <div>
                        <Button
                            onClick={() => {
                                toggleShowVoicePermissionDialog(false);
                                toggleVoicePermitted(true);

                                // После согласия начинаем записывать сообщение
                                startVoiceRecord(true);
                            }}
                            backgroundColor={ButtonBackgroundColor.lightBlue}
                        >
                            Allow
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                toggleShowVoicePermissionDialog(false);
                                toggleVoicePermitted(false);
                            }}
                            backgroundColor={ButtonBackgroundColor.white}
                        >
                            Deny
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
        ),
        [showVoicePermissionDialog, startVoiceRecord]
    );

    const mainButtonContainer = React.useMemo<React.ReactNode>(
        () => {
            // TODO: нужен плавный transition
            const track = mode === InputAreaMode.Voice && isRecording ? (
                <Stack $direction="row" $alignItems="center" $gap={theme.spaces.space10}>
                    <Stack $direction="row" $alignItems="center" $gap={theme.spaces.space0}>
                        <VoiceDeleteIcon $color={alpha(theme.palette.text.primary, 0.2)} />
                        <VoiceMoveLeftIcon $color={alpha(theme.palette.text.primary, 0.2)} />
                    </Stack>
                    <div style={{ width: "50px", height: "50px" }}></div>
                </Stack>
            ) : (
                <div style={{ width: "50px", height: "50px" }}></div>
            );

            return (
                <div style={{
                    transform: isVoiceOnly && isRecording ? "translateX(-25px)" : undefined,
                }}>
                    <SlideSwitch
                        direction="right-to-left"
                        thumb={mainButton}
                        track={track}
                        onSlideEnd={() => {
                            changeMode(InputAreaMode.Text);
                            cancelVoiceRecord();
                        }}
                    />
                </div>
            );
        },
        [cancelVoiceRecord, changeMode, isRecording, isVoiceOnly, mainButton, mode, theme.palette.text.primary, theme.spaces.space0, theme.spaces.space10]
    );

    const input = React.useMemo(
        () => {
            if (mode === InputAreaMode.Text) {
                return (
                    <InputAreaInputStyled>
                        <MessageTextInput
                            value={text}
                            placeholder={inputPlaceholderText}
                            disabled={disabled}
                            onChange={setText}
                            onInput={handleInput}
                            onEnter={handleEnter}
                        />
                    </InputAreaInputStyled>
                );
            }

            if (mode === InputAreaMode.Voice) {
                return (
                    <InputAreaInputStyled>
                        <MessageVoiceInput
                            isRecording={isRecording}
                            value={audioStream}
                            time={timeElapsed}
                        />
                    </InputAreaInputStyled>
                );
            }

            return <></>
        },
        [audioStream, disabled, handleEnter, handleInput, inputPlaceholderText, isRecording, mode, text, timeElapsed]
    );

    if (mode === InputAreaMode.None) {
        return (
            <InputAreaContainerInactiveStyled>
                <InputAreaTypographyInactiveStyled $variant="body2">
                    {inputPlaceholderText}
                </InputAreaTypographyInactiveStyled>
            </InputAreaContainerInactiveStyled>
        );
    }

    if (isVoiceOnly) {
        return (
            <InputAreaVoiceOnlyContainerStyled>
                {isRecording && (
                    <InputAreaVoiceOnlyInputContainerStyled>
                        {input}
                    </InputAreaVoiceOnlyInputContainerStyled>
                )}
                {mainButtonContainer}
                <Typography
                    $variant="caption"
                    color={alpha(theme.palette.text.primary, 0.4)}
                    style={{ userSelect: "none" }}
                >
                    Press to record
                </Typography>
                {voicePermissionDialog}
            </InputAreaVoiceOnlyContainerStyled>
        );
    }

    return (
        <InputAreaContainerStyled>
            {mode === InputAreaMode.Voice && (
                <IconButton disabled={!isRecording} onClick={() => {
                    changeMode(InputAreaMode.Text);
                    cancelVoiceRecord();
                }}>
                    <CloseIcon $color={alpha(theme.palette.text.primary, 0.4)} />
                </IconButton>
            )}
            {input}
            {mainButtonContainer}
            {voicePermissionDialog}
        </InputAreaContainerStyled>
    );
};

export default InputArea;
