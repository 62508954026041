import React from "react";

export class ImageExpandContextType {
    public selectedSrc: string = ""
    public carouselImages: MediaMeta[] = []

    showImageExpand: (selectedSrc: string, carouselImages: MediaMeta[]) => void = (selectedSrc, gallerySrcs) => { }
}

const ImageExpandContext = React.createContext<ImageExpandContextType>(new ImageExpandContextType());

export default ImageExpandContext;

export interface MediaMeta {
    url: string,
    aspectRatio: number,
    width: number,
    height: number,
    previewUrl: string
}

export function useImageExpandContext(): ImageExpandContextType {
    const result = React.useContext(ImageExpandContext);

    if (!result) {
        throw new Error("You must use this hook within context provider");
    }

    return result;
}

