import React from "react"
import { forwardRef } from "react"
import { InterviewStage, InterviewStatus } from "../../apiClient"
import { ConnectionState } from "../../models/ConnectionStateEnum"
import { FooterStyled } from "./Footer.styled"
import InputArea, { InputType } from "../InputArea/InputArea"
import { useChatStateContext } from "../../models/ChatStateContext"
import useMediaRecorderOptions from "../../services/Audio/useMediaRecorderOptions"
import { BaseMessageRequest } from "../../models/Message"

// TODO: избавиться от prop-drilling
interface FooterProps {
    onMessageSent: (message: BaseMessageRequest) => void;
    onAggregateMessageSent: (messageId: number, isStart: boolean) => void;
    disabled: boolean;
    interviewStatus: InterviewStatus;
    enabledText: string;
    disabledText: string;
    conversationEndText: string;
    connectionState: ConnectionState;
}

const Footer = forwardRef<HTMLDivElement, FooterProps>((props, ref) => {

    const { isMediaMessagesEnabled, interviewStage } = useChatStateContext();
    const { interviewStatus } = props;

    const mediaRecorderOptions = useMediaRecorderOptions();

    const allowVoice = React.useMemo(
        () => {
            if (!isMediaMessagesEnabled) {
                return false;
            }

            if (interviewStage === InterviewStage.Acquaintance) {
                return false;
            }

            // Если не поддеижвается медиа-формат записи голосвых сообщений
            if (mediaRecorderOptions === null) {
                return false;
            }

            return true;
        },
        [interviewStage, isMediaMessagesEnabled, mediaRecorderOptions]
    );

    const allowedInputTypes = React.useMemo<InputType[]>(
        () => {
            const result: InputType[] = [];

            if (interviewStatus !== InterviewStatus.Open) {
                return result;
            }

            if (allowVoice) {
                result.push(InputType.Voice);
            }

            result.push(InputType.Text);

            return result;
        },
        [allowVoice, interviewStatus]
    );

    return (
        <FooterStyled ref={ref}>
            <InputArea
                allowedInputTypes={allowedInputTypes}
                onMessageSent={props.onMessageSent}
                onMessageAggregateSent={props.onAggregateMessageSent}
                disabled={props.disabled}
                interviewStatus={props.interviewStatus}
                enabledText={props.enabledText}
                disabledText={props.disabledText}
                conversationEndText={props.conversationEndText}
                connectionState={props.connectionState}
            />
        </FooterStyled>
    );
})

export default Footer;