import color from "color";
import type { StandardLonghandProperties } from 'csstype';

/** Меняет alpha-канал в цвете */
export function alpha(c: StandardLonghandProperties["color"], alpha: number): StandardLonghandProperties["color"] {
    return color(c).alpha(alpha).string();
}

/** 
 * Шаблон медиа-события CSS на размер экрана
 * 
 * @example
 * \@media ${({ theme }) => maxWidth(theme.breakpoints.tablet)} {
 *     ...
 * }
 * */
export function maxWidth(value: string): string {
    return `(max-width: ${value})`;
}
