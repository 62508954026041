import { useCallback, useContext, useState } from "react"
import { keyframes, styled } from "styled-components"
import Button, { ButtonBackgroundColor } from "./Button/Button"
import Modal from "./Modal/Modal"
import TypingDots from "./TypingDots"
import ChatStateContext from "../models/ChatStateContext"
import { InterviewStatus, MessageRejectRequestModel } from "../apiClient"
import MessageWrapperRefContext from "../models/MessageWrapperRefContext"
import { ReactComponent as IoBug } from '../assets/IoBug.svg'
import { isDebugOrTest } from "../services/utils"
import StarRating from "./StarRating"

export interface ChatLeaveTexts {
    leave_button_text: string,
    leave_farewell: string,
    leave_modal_button_cancel: string,
    leave_modal_button_leave: string,
    leave_modal_text: string,
    leave_modal_title: string
}

interface ChatHeaderProps {
    assistantName: string,
    leaveTexts: ChatLeaveTexts,
    browserLanguage: string,
    onlineStatusText: string,
    reject: (message: MessageRejectRequestModel) => Promise<void>,
    onScoreSet: (score: number) => Promise<void>,
}

const debugToggleSize = 2;

//TODO type this ref
const ChatHeader = (props: ChatHeaderProps) => {

    const [isLeaveModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLeaveButtonPressed, setIsLeaveButtonPressed] = useState<boolean>(false);

    const chatStateContext = useContext(ChatStateContext);
    const messageWrapperRefContext = useContext(MessageWrapperRefContext);

    // const debugMessagesToggleClickHandler = useCallback(() => {
    //     localStorage.setItem("showDebugMessages", (!isDebugMessagesDisplayed).toString());
    //     toggleDebugMessagesDisplay(!isDebugMessagesDisplayed);
    // }, [isDebugMessagesDisplayed])

    const scrollToPinnedMessage = () => {
        const message = messageWrapperRefContext.messageWrapperRef?.current?.querySelectorAll(`[data-message-id="${chatStateContext.pinnedMessage!.id}"]`)[0];
        if (message) {
            message.scrollIntoView();
        }
    }

    const leaveModalCancelClickHandler = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const leaveButtonClickHandler = useCallback(async () => {
        if (isLeaveButtonPressed === false) {
            setIsLeaveButtonPressed(true);
            await props.reject({
                browserLanguage: props.browserLanguage,
                leave: true
            });
        }
    }, [isLeaveButtonPressed, props])

    const modalContent = (
        <>
            <ModalBackdropStyled onClick={leaveModalCancelClickHandler} />
            <ModalWrapperStyled>
                <ModalBoxStyled>
                    <ModalCrossButtonStyled onClick={leaveModalCancelClickHandler} />
                    <ModalTitleStyled>{props.leaveTexts.leave_modal_title}</ModalTitleStyled>
                    <span>{props.leaveTexts.leave_modal_text}</span>
                    {!chatStateContext.isTestInterview &&
                        <StarRating
                            questionText={chatStateContext.conversationNaturalnessScoreRequestText}
                            onSubmit={props.onScoreSet}
                            isScoreSet={chatStateContext.score != null} />
                    }
                    <ModalButtonContainerStyled>
                        <Button key="1" onClick={() => setIsModalOpen(false)} backgroundColor={ButtonBackgroundColor.white}>{props.leaveTexts.leave_modal_button_cancel}</Button>
                        <Button key="2" onClick={leaveButtonClickHandler} backgroundColor={ButtonBackgroundColor.red}>{isLeaveButtonPressed ? (<TypingDots />) : props.leaveTexts.leave_modal_button_leave}</Button>
                    </ModalButtonContainerStyled>
                </ModalBoxStyled>
            </ModalWrapperStyled>
        </>
    )

    return (
        <>
            <PinnedMessageWrapperStyled>
                <ChatHeaderRowStyled>
                    <ProgressBarStyled>
                        <ProgressStyled className="test" $progress={chatStateContext.progress}>
                            <ProgressBlinkStyled />
                        </ProgressStyled>
                    </ProgressBarStyled>
                    <ChatHeaderRowContentStyled>
                        <ChatHeaderTextsWrapperStyled>
                            <ChatHeaderTextStyled style={{ fontSize: "0.95rem", fontWeight: "bold" }}>{props.assistantName}</ChatHeaderTextStyled>
                            <ChatHeaderTextStyled style={{ fontSize: "0.85rem", color: "#3CA4DF" }}>{props.onlineStatusText}</ChatHeaderTextStyled>
                        </ChatHeaderTextsWrapperStyled>

                        {isDebugOrTest() &&
                            <DebugToggleStyled size={debugToggleSize} isEnabled={chatStateContext.showDebugMessages} onClick={chatStateContext.toggleShowDebugMessages} title="Toggle debug messages display">
                                <DebugToggleIconStyled>
                                    <IoBug />
                                </DebugToggleIconStyled>
                                {/* <IoBug style={{ width:`${debugToggleSize}rem`, height:`${debugToggleSize}rem` }} /> */}
                            </DebugToggleStyled>
                        }

                        <ChatHeaderLeaveButton onClick={() => setIsModalOpen(true)}>{props.leaveTexts.leave_button_text}</ChatHeaderLeaveButton>
                    </ChatHeaderRowContentStyled>
                </ChatHeaderRowStyled>
                {chatStateContext.pinnedMessage && <PinnedMessageStyled onClick={scrollToPinnedMessage}>
                    <PinnedMesssageLineStyled />
                    <PinnedMessageImageWrapperStyled imageUrl={chatStateContext.pinnedMessage.imageUrl} aspectRatio={chatStateContext.pinnedMessage.aspectRatio} />
                    <PinnedMessageTextsWrapperStyled>
                        <ChatHeaderTextStyled style={{ fontSize: "0.85rem", fontWeight: "bold", color: "#3CA4DF" }}>{chatStateContext.pinnedMessage.title}</ChatHeaderTextStyled>
                        <PinnedMessageTextStyled dangerouslySetInnerHTML={{ __html: chatStateContext.pinnedMessage?.messageText }}></PinnedMessageTextStyled>
                    </PinnedMessageTextsWrapperStyled>
                </PinnedMessageStyled>}
            </PinnedMessageWrapperStyled>
            {isLeaveModalOpen && chatStateContext.interviewStatus === InterviewStatus.Open && <Modal>
                {modalContent}
            </Modal>}
        </>
    )
}

interface PinnedMessageImageWrapperStyledProps {
    imageUrl: string,
    aspectRatio: number
}

const DebugToggleStyled = styled.div<{ size: number, isEnabled: boolean }>`
display: flex;
border-radius:50%;
height: ${props => `${props.size * 1.25}rem`};
width: ${props => `${props.size * 1.25}rem`};
opacity: ${props => props.isEnabled ? 1 : 0.4};
transition: transform 0.2s ease, opacity 0.2s ease;
justify-content: center;
align-items: center;

&:hover {
    background-color: #00000014;
    transform: scale(1.1);
}
`

const DebugToggleIconStyled = styled.div`
width:${debugToggleSize}rem;
height:${debugToggleSize}rem;
`

const PinnedMessageImageWrapperStyled = styled.div<PinnedMessageImageWrapperStyledProps>`
display: flex;
background: url(${props => props.imageUrl}) no-repeat;
background-size: contain;
background-position: center;
max-width: 20%;
aspect-ratio: ${props => props.aspectRatio};
height: 100%;
`

const ProgressBarStyled = styled.div`
width: 100%;
overflow: hidden;
height: 0.3rem;
position: absolute;
`

interface ProgressStyledProps {
    $progress: number
}

const blinkAnimation = keyframes`
  0% {
    width: 0%;
    opacity: 0.5;
  }
  10% {
    width: 100%;
    opacity: 0.3;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
`;

const ProgressStyled = styled.div<ProgressStyledProps>`
width: ${props => props.$progress}%; 
background: rgb(60, 164, 223);
height: 100%;
position: relative;
transition: width 0.5s ease-in-out;
`

const ProgressBlinkStyled = styled.div`
position: absolute;
height: 100%;
background: rgba(255, 255, 255, 1); // You can adjust this color for the blinking effect
animation: ${blinkAnimation} 3s infinite; // Run the blinkAnimation every second infinitely
`

const ChatHeaderRowContentStyled = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-inline-start: 1rem;
align-items: center;
`

const ModalCrossButtonStyled = styled.div`
  position: absolute;
  display: flex;
  user-select: none;
  align-items: center;
  right: -1.5rem;
  top: -1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 22;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  
  &:hover{
    transform: scale(1.15);  
  }
  
  &:before, &:after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 1.25rem;
    width: 0.125rem;
    background-color: #FFFFFF;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  `

const ModalTitleStyled = styled.h2`
margin-block: 0;
`

const ModalButtonContainerStyled = styled.div`
display:flex;
flex-direction: row;
gap: 0.5rem;
min-width: 50%;
max-width: 100%;
align-self: end;
`

const ModalBackdropStyled = styled.div`
position:absolute;
top: 0;
left:0;
width: 100%;
height: 100%;
z-index: 20;
background-color: #2C2C2E;
opacity: 0.8;
`

const ModalWrapperStyled = styled.div`
position:relative;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`
const ModalBoxStyled = styled.div`
position: absolute;
background-color: white;
z-index: 21;
max-width: 85%;
border-radius: 1rem;
padding: 1.5rem;
gap: 1.5rem;
display: flex;
flex-direction: column;
text-align: start;

@media (min-width: 800px) {
    max-width: 600px;
  }
`

const ChatHeaderTextsWrapperStyled = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0.6rem;
`

const ChatHeaderLeaveButton = styled.div`
display: flex;
align-items: center;
padding: 0.5rem 1rem;
height: 100%;
opacity: 0.7;
cursor: pointer;
user-select: none;

&:hover {
    box-shadow: 0 1px 5px -1px #00000036;
    opacity: 1;
}
`

const PinnedMessageWrapperStyled = styled.div`
box-shadow: 0 1px 5px -1px #00000036;
width: 100%;
z-index: 1;
`

const PinnedMessageTextsWrapperStyled = styled.div`
display:flex;
flex-direction: column;
height: 100%;
justify-content: space-evenly;
min-width: 0;
`

const PinnedMesssageLineStyled = styled.div`
height: auto;
border-left: 3px solid;
border-color: #3CA4DF;
`

const ChatHeaderTextStyled = styled.span`
display: flex;
line-height: normal;
`

const ChatHeaderStyled = styled.div`
display: flex;
height: 56px;
width: 100%;
flex-shrink: 0;
flex-direction: column;
justify-content: center;
gap: 0.5rem;
`

const ChatHeaderRowStyled = styled(ChatHeaderStyled)`
border-bottom: 1px solid #00000014;
justify-content: space-between;
position: relative;
`

const PinnedMessageStyled = styled(ChatHeaderStyled)`
flex-direction: row;
justify-content: start;
cursor: pointer;
padding: 0.6rem;
padding-inline:1rem;
`

const PinnedMessageTextStyled = styled(ChatHeaderTextStyled)`
overflow: hidden;
display: inline-block;
text-align: start;
text-overflow: ellipsis;
white-space: nowrap;
font-size: 0.85rem;
`

export default ChatHeader