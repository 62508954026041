import styled, { ComplexColor, DefaultTheme } from "styled-components";
import { alpha } from "../../themes/utils";

export interface IconButtonProps {
    $size?: keyof DefaultTheme["shape"]["buttonSize"];
    $color?: React.CSSProperties["color"] | ComplexColor;
}

export function getButtonColor(
    color: IconButtonProps["$color"],
    a?: (x: ComplexColor) => React.CSSProperties["color"],
    b?: (x: string) => React.CSSProperties["color"]
): React.CSSProperties["color"] {

    if (color === undefined) {
        return "transparent";
    }

    if (typeof color === "string") {
        return !!b ? b(color) : color;
    }

    return !!a ? a(color) : color.main;
}

const IconButton = styled.button<IconButtonProps>`
    width: ${props => props.theme.shape["buttonSize"][props.$size || "meduim"]};
    height: ${props => props.theme.shape["buttonSize"][props.$size || "meduim"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    background-color: ${props => getButtonColor(props.$color)};
    transition-property: background-color;
    transition-duration: 0.25s;
    user-select: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.25;
        pointer-events: none;
        cursor: default;
    }
    
    &:not(&:disabled) {
        &:active {
            background-color: ${props => getButtonColor(props.$color, x => x.light, x => alpha(x, 0.6))};
        }

        @media (hover: hover) {
            &:hover {
                background-color: ${props => getButtonColor(props.$color, x => x.light, x => alpha(x, 0.6))};
            }
        }
    }
`

export default IconButton;