import styled from "styled-components";
import { alpha, maxWidth } from "../../themes/utils";

export const DialogBackdrop = styled("div") <{ $isTouchMode: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => alpha(props.theme.palette.common.black, 0.1)};
    z-index: 100;
`

export const DialogRoot = styled("div") <{ $isTouchMode: boolean, $width: string }>`
    position: absolute;
    min-width: ${props => `min(${props.$width}, 100%)`};
    top: ${props => !props.$isTouchMode ? "50%" : undefined};
    left: 50%;
    bottom: ${props => !props.$isTouchMode ? undefined : "0px"};
    transform: ${props => !props.$isTouchMode ? "translate(-50%, -50%)" : "translate(-50%, 0)"};
`

export const DialogPaper = styled("div") <{ $isTouchMode: boolean }>`
    position: relative;
    background-color: ${props => props.theme.palette.background.default};
    border-top-right-radius: ${props => `calc(${props.theme.shape.borderRadius} * 2);`};
    border-top-left-radius: ${props => `calc(${props.theme.shape.borderRadius} * 2);`};
    border-bottom-right-radius: ${props => !props.$isTouchMode ? `calc(${props.theme.shape.borderRadius} * 2);` : "0px"};
    border-bottom-left-radius: ${props => !props.$isTouchMode ? `calc(${props.theme.shape.borderRadius} * 2);` : "0px"};
    box-shadow: 0px 0px 2px 0px #0001, 0px 8px 16px 0px #0001;

    &:before {
        display: ${props => !props.$isTouchMode ? "none" : "block"};
        content: "";
        position: absolute;
        width: 40px;
        height: 2px;
        top: ${props => props.theme.spaces.space5};
        left: 50%;
        transform: translate(-50%, 0);
        background-color: ${props => props.theme.palette.divider};
    }
`

export const DialogHeader = styled("div").attrs((x) => ({ style: x.theme.typography.subtitle2 }))`
    padding-top: ${props => props.theme.spaces.space25};
    padding-left: ${props => props.theme.spaces.space20};
    padding-right: ${props => props.theme.spaces.space20};
    padding-bottom: ${props => props.theme.spaces.space0};
`

export const DialogBody = styled("div").attrs((x) => ({ style: x.theme.typography.body1 }))`
    padding-top: ${props => props.theme.spaces.space10};
    padding-left: ${props => props.theme.spaces.space20};
    padding-right: ${props => props.theme.spaces.space20};
    padding-bottom: ${props => props.theme.spaces.space15};
`

export const DialogFooter = styled("div")`
    display: flex;
    gap: ${props => props.theme.spaces.space10};
    padding-top: ${props => props.theme.spaces.space0};
    padding-left: ${props => props.theme.spaces.space20};
    padding-right: ${props => props.theme.spaces.space20};
    padding-bottom: ${props => props.theme.spaces.space10};

    flex-direction: row;
    justify-content: end;

    @media ${({ theme }) => maxWidth(theme.breakpoints.mobile)} {
        flex-direction: column;
        justify-content: unset;
    }
`
