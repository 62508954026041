import styled from "styled-components";
import IconButton, { getButtonColor } from "../IconButton/IconButton";
import { clamp } from "../../services/utils";
import { alpha, maxWidth } from "../../themes/utils";
import Typography from "../Typography/Typography";

export const InputAreaContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: calc(${props => props.theme.shape.borderRadius} * 2);
    border: 1px solid ${props => props.theme.palette.divider};
    padding: 4px;
    background-color: ${props => props.theme.palette.background.default};
    min-height: 50px;

    @media ${({ theme }) => maxWidth(theme.breakpoints.tablet)} {
        border-radius: 0;
        border-width: 1px 0px 0px 0px;
        padding: 0px;
    }
`

export const InputAreaContainerInactiveStyled = styled(InputAreaContainerStyled)`
    background-color: ${props => props.theme.palette.background.paper};
`

export const InputAreaTypographyInactiveStyled = styled(Typography)`
    color: ${props => alpha(props.theme.palette.text.primary, 0.4)};
    line-height: 50px;
    padding-left: ${props => props.theme.spaces.space15};
    padding-right: ${props => props.theme.spaces.space15};
    user-select: none;
`

export const InputAreaVoiceOnlyContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spaces.space10};
`

export const InputAreaVoiceOnlyInputContainerStyled = styled.div`
    z-index: 10;
    position: absolute;
    width: 90%;
    left: 50%;
    bottom: calc(100% + ${props => props.theme.spaces.space10});
    transform: translateX(-50%);
    border-radius: calc(${props => props.theme.shape.borderRadius} * 5);
    overflow: hidden;
    background-color: ${props => props.theme.palette.background.default};
    border: 3px solid ${props => props.theme.palette.background.default};
    box-shadow: 0px 0px 2px 0px #0002, 0px 8px 16px 0px #0002;

    animation-name: splash;
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    @keyframes splash {
        from {
            opacity: 0;
            transform: translateX(-50%) translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateX(-50%) translateY(0%);
        }
    }
`

export const InputAreaIconButtonStyled = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 50%;
`

export const InputAreaInputStyled = styled.div`
    flex-grow: 1;
`

export const InputAreaMainButton = styled(IconButton) <{ $currentAudioLevel: number | null }>`
    box-shadow: ${props => props.$currentAudioLevel !== null
        ? `0px 0px 0px ${clamp(props.$currentAudioLevel * 10, 0, 5)}px ${alpha(getButtonColor(props.$color), 0.5)}`
        : undefined};
    transform: ${props => props.$currentAudioLevel !== null
        ? `scale(${1 + clamp(props.$currentAudioLevel, 0.0, 0.20)})`
        : undefined};
    transition-property: transform, background-color;
    transition-duration: 0.1s, 0.25s;
`