import React from "react";
import { MessageTextInputContainerStyled, MessageTextInputTextAreaStyled } from "./MessageTextInput.styled";

interface MessageTextInputProps {
    value: string;
    placeholder: string;
    disabled: boolean;
    onChange: (value: string) => void;
    onEnter: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onInput: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

/** 
 * Элемент ввода текста: 
 * - основная фича: изменение внешнего размера под воздействием текста внутри;
 * - ключевое отличие от предшественника: взаимодействие с фантомным элементом без имератвного присвоего innerHTML и меньше хардкора */
const MessageTextInput: React.FC<MessageTextInputProps> = (props) => {

    const currentId = React.useMemo(
        () => Math.round(Math.random() * 10000000),
        []
    );

    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    const { value, placeholder, disabled, onChange, onEnter, onInput } = props;

    const maxLines = 10;

    const calcLinesCount = React.useCallback(
        () => {
            if (!!textareaRef.current) {
                const a = textareaRef.current.scrollHeight;
                const b = Number.parseFloat(window.getComputedStyle(textareaRef.current).getPropertyValue("padding-top")) || 0;
                const c = Number.parseFloat(window.getComputedStyle(textareaRef.current).getPropertyValue("padding-bottom")) || 0;
                const d = Number.parseFloat(window.getComputedStyle(textareaRef.current).getPropertyValue("line-height")) || 0;

                const innerHeight = a - (b + c);
                const lines = d > 0 ? Math.ceil(innerHeight / d) : 1;
                return Math.min(lines, maxLines);
            }

            const result = (value.match(/\n/g) || []).length + 1;
            return Math.min(result, maxLines);
        },
        [value]
    );

    const [linesCount, setLinesCount] = React.useState<number>(1);

    React.useEffect(
        () => {
            function handleResize() {
                setLinesCount(calcLinesCount());
            }

            handleResize();
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        },
        [calcLinesCount]
    );

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
        [onChange]
    );

    const handleInput = React.useCallback(
        (e: React.FormEvent<HTMLTextAreaElement>): void => onInput(e),
        [onInput]
    );

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
            if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.ctrlKey && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                onEnter(e);
            }
        },
        [onEnter]
    );

    const shadow = React.useMemo<React.ReactNode>(
        () => (
            <MessageTextInputTextAreaStyled
                id={`textarea-shadow-${currentId}`}
                ref={textareaRef}
                style={{
                    visibility: "hidden",
                    position: "absolute",
                    overflow: "hidden",
                    height: 0,
                    top: 0,
                    left: 0,
                    transform: "translateZ(0)",
                }}
                value={value}
                readOnly
                autoComplete="off"
            />
        ),
        [currentId, value]
    );

    return (
        <MessageTextInputContainerStyled $dividerLeft={false} $dividerRight={true}>
            <MessageTextInputTextAreaStyled
                id={`textarea-${currentId}`}
                rows={linesCount}
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete="off"
            />
            {shadow}
        </MessageTextInputContainerStyled>
    );
};

export default MessageTextInput;