import React from 'react';
import { SvgIconStyled, SvgIconStyledProps } from './SvgIcon.styled';

export interface SvgIconProps extends SvgIconStyledProps {
    children?: React.ReactNode;
}

const SvgIcon: React.FC<SvgIconProps> = ({ children, ...props }) => {
    return (
        <SvgIconStyled {...props}>
            {children}
        </SvgIconStyled>
    );
};

export default SvgIcon;