import styled from "styled-components"
import { DebugMessagePosition } from "../apiClient"
import { DebugMessage } from "../models/DebugMessage"
import { Message, MessageSide } from "../models/Message"
import MessageBubble from "./MessageBubble"
import SystemMessage from "./SystemMessage"
import React, { useContext } from "react"
import { ENVIRONMENT } from "../services/utils"
import ChatStateContext from "../models/ChatStateContext"

let MessageBlock = (props: MessageBlockProps) => {

  const [audioUrl, setAudioUrl] = React.useState<string | null>(null);
  const { chatAudioMessageText } = useContext(ChatStateContext);

  React.useEffect(() => {
    if (props.message?.file !== undefined && audioUrl === null) {
      setAudioUrl(URL.createObjectURL(props.message.file));
    }
    return () => {
      if (audioUrl !== null) {
        URL.revokeObjectURL(audioUrl);
        setAudioUrl(null);
      }
    }
  }, [audioUrl, props.message?.file])

  return (
    <MessageBlockContainerStyled>
      {props.debugMessages.filter(x => x.position === DebugMessagePosition.Top).map((dm, i) => (
        <SystemMessage message={dm.visibleText} tooltipMessage={dm.fulltext} key={i} />
      ))}
      <MessageBlockStyled data-message-id={props.message?.id ?? 9999} $side={props.message?.side ?? MessageSide.Bot}>
        <AudioBlockContainerStyled>
          {
            ENVIRONMENT !== 'production' &&
            audioUrl !== null &&
            <audio controls src={audioUrl} />
          }
          <MessageBubble key={2} isTypingMessage={props.isTypingMessage} message={props.message} audioMessageText={chatAudioMessageText} last={props.last} />
        </AudioBlockContainerStyled>
      </MessageBlockStyled>
      {props.debugMessages.filter(x => x.position === DebugMessagePosition.Bottom).map((dm, i) => (
        <SystemMessage message={dm.visibleText} tooltipMessage={dm.fulltext} key={i} />
      ))}
    </MessageBlockContainerStyled>
  )
}

const AudioBlockContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  align-items: flex-end;
`

const MessageBlockContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const MessageBlockStyled = styled.div<MessageBlockStyledProps>`
  display: flex;
  flex-direction: ${props => props.$side === MessageSide.Bot ? 'row' : 'row-reverse'};
  align-self: ${props => props.$side === MessageSide.Bot ? 'flex-start' : 'flex-end'};
  gap: 16px;
  width: 100%;
  padding: 0 0.5rem;

  @media (max-width: 800px) {
    gap: 12px;
    padding-left: ${props => props.$side === MessageSide.Bot ? '0px' : '1.5rem'};
    padding-right: ${props => props.$side === MessageSide.Bot ? '1.5rem' : '0px'};
  }
  `

interface MessageBlockStyledProps {
  $side: MessageSide
}

type MessageBlockProps =
  { message?: Message, isTypingMessage: boolean, last: boolean, debugMessages: DebugMessage[] }
  | { message: Message, isTypingMessage?: boolean, last: boolean, debugMessages: DebugMessage[] }

export default MessageBlock