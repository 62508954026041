export interface Message {
    id?: number,
    temp_id?: number,
    side: MessageSide,
    payload: MessagePart[],
    reaction?: string | null,
    file?: File
    isVoiceMessage?: boolean;
}

export interface MessagePart {
    type: MessagePartType,
    data: string
}

export interface MediaMessagePart extends MessagePart {
    aspectRatio: number,
    width: number,
    height: number,
    previewUrl: string,
}

export enum MessagePartType {
    Text = 1,
    Image = 2,
    Video = 3,
    Audio,
}

export enum MessageSide {
    Bot = 1,
    User = 2,
    System = 3
}


export abstract class BaseMessageRequest { }

export class TextMessageRequest extends BaseMessageRequest {
    constructor(public text: string) {
        super();
    }
}

export class VoiceMessageRequest extends BaseMessageRequest {
    constructor(public voice: File) {
        super();
    }
}
