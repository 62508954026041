import React from "react";
import styled from "styled-components";

interface StackProps {
    $direction?: React.CSSProperties["flexDirection"];
    $gap?: React.CSSProperties["gap"];
    $alignItems?: React.CSSProperties["alignItems"];
    $justifyContent?: React.CSSProperties["justifyContent"];
    $justifyItems?: React.CSSProperties["justifyItems"];
}

const Stack = styled.div<StackProps>`
    display: flex;
    flex-direction: ${props => props.$direction || "column"};
    gap: ${props => props.$gap};
    align-items: ${props => props.$alignItems};
    justify-content: ${props => props.$justifyContent};
    justify-items: ${props => props.$justifyItems};
`

export default Stack;