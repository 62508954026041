import { useContext } from "react";
import { styled } from "styled-components";
import { VideoStateContext } from "./VideoContextProvider";

const PlayerTimer = () => {

    const state = useContext(VideoStateContext);

    const formatTime = (time?: number) => {
        if (!time)
            return;

        const hours = Math.floor(time / 3600);
        time %= 3600;
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);

        if (hours > 0) {
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    return (
        <ControlsVideoTimeStyled>{formatTime(state?.videoCurrentTime)} / {formatTime(state?.videoDuration)}</ControlsVideoTimeStyled>
    )
}

export default PlayerTimer;

const ControlsVideoTimeStyled = styled.div`
white-space: nowrap;
color: white;
font-size: small;
user-select: none;
`