import React from "react";

export interface MediaRecorderOptionsWithExtension extends Omit<MediaRecorderOptions, 'mimeType'> {
    fileExtension: string;
    mimeType: string;
}

/** Если null, значит не поддерживается ни одного нужного нам аудио-кодека */
function useMediaRecorderOptions(): MediaRecorderOptionsWithExtension | null {

    const mediaRecorderOptions = React.useMemo<MediaRecorderOptionsWithExtension | null>(
        () => {
            // Форматы и кодеки отсортированы по степени возрастания противности для нас
            const formats = [
                { mimeType: "audio/ogg;codecs=opus", fileExtension: "ogg" },
                { mimeType: "audio/webm;codecs=opus", fileExtension: "webm" },
                { mimeType: "audio/webm", fileExtension: "webm" },
                { mimeType: "audio/ogg", fileExtension: "ogg" },
                { mimeType: "audio/mp4", fileExtension: "mp4" },
            ];

            const desiredAudioBitsPerSecond = 256_000;

            for (const format of formats) {
                if (MediaRecorder.isTypeSupported(format.mimeType)) {
                    return {
                        audioBitsPerSecond: desiredAudioBitsPerSecond,
                        mimeType: format.mimeType,
                        fileExtension: format.fileExtension,
                    };
                }
            }

            return null;
        },
        []
    );

    return mediaRecorderOptions;
}

export default useMediaRecorderOptions;