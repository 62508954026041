import styled, { DefaultTheme } from "styled-components";

interface TypographyProps {
    /** @default body1 */
    $variant?: keyof DefaultTheme["typography"];
}

const Typography = styled.span.attrs({ dir: "auto" })<TypographyProps>(({ theme, $variant, style, color }) => ({
    color: color || style?.color || theme.palette.text.primary,
    ...theme.typography[$variant || "body1"],
}));

export default Typography;