import React from "react";
import { GreetingModel } from "./GreetingModel";
import { ChatLeaveTexts } from "../components/ChatHeader";
import FinalScreenData from "./FinalScreenData";
import PinnedStimulusMessage from "./PinnedStimulusMessage";
import { InterviewStage, InterviewStatus } from "../apiClient";
import { ConnectionState } from "./ConnectionStateEnum";

export interface ChatContextState {
    progress: number;
    interviewUid: string;
    greetingModel: GreetingModel;
    assistantName: string;
    assistantImageUrl: string;
    chatLeaveTexts: ChatLeaveTexts;
    footerText: string;
    currentQuestionId: string;

    interviewStatus: InterviewStatus;
    statusText: string;

    interviewStage: InterviewStage | null;
    finalScreenData?: FinalScreenData;

    isLoading: boolean;
    isInterviewBuildSuccess: boolean;
    isInputBlocked: boolean;

    pinnedMessage?: PinnedStimulusMessage;

    connectionState: ConnectionState;
    reconnectionText: string;
    disconnectedText: string;

    interviewLanguage: string;

    agentOnlineStatus: string;
    chatEnabledText: string;
    chatDisabledText: string;
    conversationNaturalnessScoreRequestText: string;
    chatConversationEndText: string;

    showDebugMessages: boolean;
    isTestInterview: boolean;
    score: number | null;
    chatAudioRecordingText: string;
    chatAudioMessageText: string;
    isMediaMessagesEnabled: boolean;
    isMediaUploadError: boolean;
}

export class ChatStateContextType implements ChatContextState {
    public progress: number = 0;
    public interviewUid: string = "";
    public greetingModel: GreetingModel = {
        greetingText: "",
        acceptButtonText: "",
        rejectButtonText: "",
        languages: []
    };
    public assistantName: string = "";
    public assistantImageUrl: string = "";
    public chatLeaveTexts: ChatLeaveTexts = {
        leave_button_text: "",
        leave_modal_text: "",
        leave_modal_title: "",
        leave_modal_button_cancel: "",
        leave_modal_button_leave: "",
        leave_farewell: ""
    };
    public finalScreenData?: FinalScreenData = undefined;
    public isLoading: boolean = true;
    public isInterviewBuildSuccess = true;
    public footerText: string = "";
    public isInputBlocked: boolean = true;
    public currentQuestionId: string = "";
    public interviewStage: InterviewStage | null = null;
    public pinnedMessage?: PinnedStimulusMessage = undefined;
    public connectionState: ConnectionState = ConnectionState.Connected;
    public reconnectionText: string = "";
    public disconnectedText: string = "";
    public interviewStatus: InterviewStatus = InterviewStatus.Open;
    public statusText: string = "";
    public interviewLanguage: string = "";

    public agentOnlineStatus: string = "";
    public chatEnabledText: string = "";
    public chatDisabledText: string = "";
    public conversationNaturalnessScoreRequestText: string = "";
    public chatConversationEndText: string = "";

    public showDebugMessages: boolean = false;

    public isTestInterview: boolean = false;
    public score: number | null = null;
    public chatAudioRecordingText: string = "";
    public chatAudioMessageText: string = "";
    public isMediaMessagesEnabled: boolean = false;
    public isMediaUploadError: boolean = false;

    setProgress: (progress: number) => void = (progress) => { }
    setInterviewUid: (interviewUid: string) => void = (interviewUid) => { }
    setGreetingModel: (greetingModel: GreetingModel) => void = (greetingModel) => { }
    setAssistantName: (assistantName: string) => void = (assistantName) => { }
    setAssistantImageUrl: (assistantImageUrl: string) => void = (assistantImageUrl) => { }
    setChatLeaveTexts: (chatLeaveTexts: ChatLeaveTexts) => void = (chatLeaveTexts) => { }
    setFinalScreen: (data: FinalScreenData) => void = (data) => { }
    setIsLoading: (isLoading: boolean) => void = (isLoading) => { }
    setIsInputBlocked: (isInputBlocked: boolean) => void = (isInputBlocked) => { }
    setCurrentQuestionId: (currentQuestionId: string) => void = (currentQuestionId) => { }
    setInterviewStage: (interviewStage: InterviewStage | null) => void = (interviewStage) => { }
    setPinnedMessage: (pinnedMessage: PinnedStimulusMessage) => void = (pinnedMessage) => { }
    setConnectionState: (connectionState: ConnectionState) => void = (connectionState) => { }
    setReconnectionText: (reconnectionText: string) => void = (reconnectionText) => { }
    setDisconnectedText: (disconnectedText: string) => void = (disconnectedText) => { }
    setInterviewStatus: (interviewStatus: InterviewStatus) => void = (interviewStatus) => { }
    setStatusText: (statusText: string) => void = (statusText) => { }
    setInterviewLanguage: (interviewLanguage: string) => void = (interviewLanguage) => { }
    setIsInterviewBuildSuccess: (isInterviewBuildSuccess: boolean) => void = (isInterviewBuildSuccess) => { }

    toggleShowDebugMessages: () => void = () => { }

    setChatState: (chatState: ChatContextState) => void = (chatState) => { }
    setInterviewScore: (score: number) => void = (score) => { }
    toggleIsMediaUploadError: (isMediaUploadError: boolean) => void = (isMediaUploadError) => { }
}

const ChatStateContext = React.createContext<ChatStateContextType>(new ChatStateContextType());

export default ChatStateContext;

export function useChatStateContext(): ChatStateContextType {
    const result = React.useContext(ChatStateContext);

    if (!result) {
        throw new Error("You must use this hook within context provider");
    }

    return result;
}
