import React from "react";
import { DialogBackdrop, DialogPaper, DialogRoot } from "./Dialog.styled";
import { useTouchScreen } from "../../services/utils";

interface DialogProps {
    open: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    width?: string;
}

const Dialog: React.FC<DialogProps> = (props) => {

    const isTouchScreen = useTouchScreen();

    const { open, width = "480px" } = props;

    if (!open) {
        return <></>
    }

    return (
        <DialogBackdrop $isTouchMode={isTouchScreen}>
            <DialogRoot $isTouchMode={isTouchScreen} $width={width}>
                <DialogPaper $isTouchMode={isTouchScreen}>
                    {props.children}
                </DialogPaper>
            </DialogRoot>
        </DialogBackdrop >
    );
};

export default Dialog;