import { DefaultTheme, styled } from 'styled-components';

export interface SvgIconStyledProps extends React.HTMLAttributes<HTMLDivElement> {
    $color?: React.CSSProperties["color"];
    $fontSize?: keyof DefaultTheme["typography"];
    $size?: React.CSSProperties["fontSize"];
}

/**
 * Универсальный отрисовщик SVG-иконок. Вдохновлен одноименным элементом в MUI.
 * Умеет заменять цвет содержащей иконки, если это #FF00FF (Magenta).
 * Почему такой вырвиглазный дефолтный цвет? Потому, что так проще всего понять, что ничего не работает.
 */
// TODO: класс fai-chat-icon выпилить, чтобы даже не было соблазна менять цвет через селекторы
export const SvgIconStyled = styled.div.attrs({ className: 'fai-chat-icon', }) <SvgIconStyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
        width: ${({ $fontSize, $size, theme }) => getIconSize($fontSize, $size, theme)};
        height: ${({ $fontSize, $size, theme }) => getIconSize($fontSize, $size, theme)};
    }
    & path[fill="#FF00FF"] {
        fill: ${({ $color, theme }) => $color || theme.palette.text.primary};
    }
    & path[stroke="#FF00FF"] {
        stroke: ${({ $color, theme }) => $color || theme.palette.text.primary};
    }
`

function getIconSize(fontSize: SvgIconStyledProps["$fontSize"], size: SvgIconStyledProps["$size"], theme: DefaultTheme) {
    return size || (fontSize ? theme.typography[fontSize].fontSize : theme.typography.body1.fontSize);
}