import React, { useContext, useEffect, useRef } from "react"
import MessagesContext from "../models/MessagesContext"
import Footer from "./Footer/Footer"
import MessageWrapper from "./MessageWrapper"
import ChatHeader from "./ChatHeader"
import { useChatHub } from "../services/useChatHub"
import { InterviewRole, InterviewStage } from "../apiClient"
import { DropdownOption } from "./Dropdown"
import MessageWrapperRefContext from "../models/MessageWrapperRefContext";
import Greeting from "./Greeting/Greeting";
import { useChatClient, useTextDirection } from "../services/utils";
import VersionInfo from "./VersionInfo";
import ChatFooter from "./ChatFooter";
import { HubConnectionState } from "@microsoft/signalr";
import ChatStateContext from "../models/ChatStateContext";
import { BaseMessageRequest, MessageSide, TextMessageRequest, VoiceMessageRequest } from "../models/Message"

export interface ChatProps {
  interviewUid: string,
  browserLanguage: string,
  greetingLanguageSelectHandler: (languageCode: string) => void,
}

const Chat = (props: ChatProps) => {
  const footerRef = useRef<HTMLDivElement>(null);

  const messageWrapperRefContext = useContext(MessageWrapperRefContext);
  const messagesContext = useContext(MessagesContext);

  const appState = useContext(ChatStateContext);
  const chatClient = useChatClient();

  useTextDirection(appState.interviewLanguage);

  const { connection, accept, reject, send, sendAudio, aggregate, connect, callContinue, setLanguage, setScore } = useChatHub({
    interviewUid: props.interviewUid,
    browserLanguage: props.browserLanguage
  });

  useEffect(() => {
    if (connection.state === HubConnectionState.Disconnected) {
      connect();
    }
    else {
      callContinue();
    }
  }, [callContinue, connect, connection])

  const greetingLanguageSelectHandler = async (code: string) => {
    appState.setIsLoading(true);
    await setLanguage(code);

    const query = new URLSearchParams(window.location.search);

    if (query.has("l")) {
      query.delete("l");
    }

    window.location.search = query.toString();
  }

  const acceptButtonHandler = async () => {
    await accept(props.browserLanguage);
  }

  const rejectButtonHandler = async () => {
    await reject({
      browserLanguage: props.browserLanguage,
      leave: false
    });
  }

  const getAudioDuration = React.useCallback((file: File): Promise<number> => {
    return new Promise((resolve) => {
      const audioContext = new AudioContext();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const audioBuffer = await audioContext.decodeAudioData(
          reader.result as ArrayBuffer
        );
        resolve(audioBuffer.duration);
      };
    });
  }, [])

  const sendAcquaintanceMessageToServer = React.useCallback(
    async (text: string) => {

      appState.setIsInputBlocked(true);

      messagesContext.setIsModeratorTypingAsync(true);

      await send({
        question_id: "Acquaintance",
        text: text,
        author: InterviewRole.User
      });

      var messageId = messagesContext.messages.filter(m => m.side === MessageSide.User).pop();

      await aggregate(
        messageId?.id as number,
        appState.currentQuestionId,
        true
      )
    },
    [aggregate, appState, messagesContext, send]
  );

  const sendMessageToServer = React.useCallback(
    async (text: string) => {
      await send({
        question_id: appState.currentQuestionId,
        text: text,
        author: InterviewRole.User
      })

      var messageId = messagesContext.messages.filter(m => m.side === MessageSide.User).pop();

      await aggregate(
        messageId?.id as number,
        appState.currentQuestionId,
        true
      )
    },
    [aggregate, appState.currentQuestionId, messagesContext.messages, send]
  );

  const sendAggregateToServer = async (messageId: number, isStartDown: boolean) => {
    await aggregate(
      messageId,
      appState.currentQuestionId,
      isStartDown
    )
  }

  const onScoreSet = async (scoreNum: number, performRedirect: boolean) => {
    return setScore({ score: scoreNum, performRedirect: performRedirect })
  }

  const addMessageInstantAsync = messagesContext.addMessageInstantAsync

  const sendAudioMessageToServer = React.useCallback(
    async (voice: File) => {
      try {
        await addMessageInstantAsync({
          side: MessageSide.User,
          payload: [],
          file: voice,
          isVoiceMessage: true,
        });
        const response = await chatClient.uploadMediaFile(
          appState.interviewUid,
          appState.currentQuestionId,
          props.browserLanguage,
          { data: voice, fileName: voice.name }
        );

        /// null возращается в случае ошибки загрузки файла,
        // в том же enpoint-е также происходит отправка результатов по SignalIR
        // поэтому здесь просто не отправляем сообщения на расшифровку
        if (response.messageId === null) {
          return;
        }

        const contentDuration = await getAudioDuration(voice);
        messagesContext.toggleIsAudioMessageProcessing(true);
        messagesContext.scrollToBottom();
        await sendAudio({
          messageId: response.messageId,
          question_id: appState.currentQuestionId,
          content: "",
          author: InterviewRole.User,
          content_duration: Math.ceil(contentDuration),
          audioSource: voice
        })
        await aggregate(response.messageId, appState.currentQuestionId, true);
      }
      catch (exp) {
        appState.toggleIsMediaUploadError(true);
      }
    },
    [addMessageInstantAsync, aggregate, appState, chatClient, getAudioDuration, messagesContext, props.browserLanguage, sendAudio]
  )

  const onMessageSent = React.useCallback(
    async (message: BaseMessageRequest) => {
      if (appState.isInputBlocked) {
        return;
      }

      if (message instanceof TextMessageRequest) {
        if (appState.interviewStage === InterviewStage.Acquaintance) {
          await sendAcquaintanceMessageToServer(message.text);
        }
        else {
          await sendMessageToServer(message.text);
        }
      }
      else if (message instanceof VoiceMessageRequest) {
        await sendAudioMessageToServer(message.voice);
      }
      else {
        throw new Error(`Not implemented for ${message}`);
      }
    },
    [appState.interviewStage, appState.isInputBlocked, sendAcquaintanceMessageToServer, sendAudioMessageToServer, sendMessageToServer]
  );


  let topContentPart = <></>;
  let overallContent = <></>;

  if (appState.interviewStage === InterviewStage.Greeting && appState.greetingModel) {
    const { languages } = appState.greetingModel;
    // Перед отрисовкой, языки следует отсортировать по их "нужности".
    // Порядок "нужности" следующий: 1. текущий язык чата. 2. языки из navigator.languages в порядке очередности
    // 3. всё прочее в дефолтном порядке.
    const languagesSet = new Set<string>([appState.interviewLanguage.toLowerCase(), ...navigator.languages.map(i => i.toLowerCase())]);
    Array.from(languagesSet).reverse().forEach(lang => {
      languages.sort((a, b) => {
        return a.code.toLowerCase() === lang
          ? -1
          : b.code.toLowerCase() === lang
            ? 1
            : 0;
      });
    });

    let languageDropdownOptions: DropdownOption[] = languages.map(x => {
      return {
        name: x.name,
        code: x.code,
        fn: () => { greetingLanguageSelectHandler(x.code) }
      }
    });

    topContentPart = <Greeting
      acceptButtonHandler={acceptButtonHandler}
      rejectButtonHandler={rejectButtonHandler}
      languageDropdownOptions={languageDropdownOptions}
      selectedLanguageCode={appState.interviewLanguage}
      interviewUid={props.interviewUid}
      footerText={appState.footerText} />
  }

  if (!appState.isLoading) {
    if (appState.interviewStage === InterviewStage.Greeting && appState.greetingModel) {
      overallContent = topContentPart
    }
    else {
      if (appState.chatLeaveTexts !== null) {
        overallContent = (<>
          <ChatHeader
            assistantName={appState.assistantName}
            leaveTexts={appState.chatLeaveTexts}
            browserLanguage={props.browserLanguage}
            reject={reject}
            onlineStatusText={appState.agentOnlineStatus}
            onScoreSet={(score) => onScoreSet(score, false)}
          />
          <MessageWrapper
            imageUrl={appState.assistantImageUrl}
            connectionState={appState.connectionState}
            onScoreSet={(score) => onScoreSet(score, true)}
            ref={(ref) => {
              if (ref && messageWrapperRefContext && !messageWrapperRefContext.messageWrapperRef?.current) {
                messageWrapperRefContext.setMessageWrapperRef({ current: ref });
              }
            }} />
          <Footer
            ref={footerRef}
            onMessageSent={onMessageSent}
            onAggregateMessageSent={sendAggregateToServer}
            disabled={appState.isInputBlocked || appState.isMediaUploadError}
            interviewStatus={appState.interviewStatus}
            enabledText={appState.chatEnabledText}
            disabledText={appState.chatDisabledText}
            conversationEndText={appState.chatConversationEndText}
            connectionState={appState.connectionState} />
          <div>
            <ChatFooter text={appState.footerText} />
          </div>
        </>)
      }
    }
  }

  return (
    <>
      <VersionInfo />
      {overallContent}
    </>)
}

Chat.whyDidYouRender = true;

export default Chat