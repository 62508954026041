import React, { Component, PropsWithChildren } from "react";
import { Message, MessagePartType, MessageSide } from "../models/Message";
import MessagesContext from "../models/MessagesContext";
import { Queue } from "../models/Queue";
import { ENVIRONMENT, sleepAsync } from "../services/utils";
import { HubMessageText, InterviewRole } from "../apiClient";
import MessageWrapperRefContext from "../models/MessageWrapperRefContext";
import { DebugMessage } from "../models/DebugMessage";

interface QueueItem {
  type: QueueItemType
}

enum QueueItemType {
  Message,
  TypingControl
}

interface QueueItemMessage extends QueueItem {
  message: Message,
}

interface QueueItemTypingControl extends QueueItem {
  isTyping: boolean,
}

class MessagesContextProvider extends Component<PropsWithChildren<MessagesContextProviderProps>, MessagesContextProviderState> {

  static contextType = MessageWrapperRefContext;
  context!: React.ContextType<typeof MessageWrapperRefContext>;

  queue = new Queue<QueueItemMessage | QueueItemTypingControl>();
  isProcessing: boolean = false;

  constructor(props: PropsWithChildren<MessagesContextProviderProps>) {
    super(props);
    this.state = {
      messages: [...this.props.messages],
      isModeratorTyping: false,
      debugMessages: new Map<number, DebugMessage[]>(),
      isAudioMessageProcessing: false,
      isAudioMessageRecording: false
    };
  }

  _setStateAsync =
    <K extends keyof MessagesContextProviderState>(newState: (Pick<MessagesContextProviderState, K> | MessagesContextProviderState)) =>
      new Promise<void>(resolve => this.setState<K>(newState, resolve));

  _addMessageAsync = async (newMessage: Message) => {
    let lastId = this.state.messages.at(-1)?.id ?? 0;
    newMessage.id = newMessage.id ?? lastId + 1;
    await this._setStateAsync({ messages: [...this.state.messages, newMessage], isModeratorTyping: false });
    return newMessage.id;
  }

  _processQueue = async () => {
    if (this.isProcessing) return;

    this.isProcessing = true;
    while (this.queue.size() > 0) {
      let queueItem = this.queue.dequeue()!;

      switch (queueItem.type) {
        case QueueItemType.Message:
          const maessageItem = queueItem as QueueItemMessage;
          await this._addMessageAsync(maessageItem.message);
          break;
        case QueueItemType.TypingControl:
          const typingItem = queueItem as QueueItemTypingControl;

          if (typingItem.isTyping) await sleepAsync(200);

          await this._setStateAsync({ isModeratorTyping: typingItem.isTyping });

          if (typingItem.isTyping) await sleepAsync(1000);

          break;
        default:
          console.error('Unknown queue item type');
          break;
      }

      this.scrollToBottom();

    }
    this.isProcessing = false;
  }

  scrollToBottom: () => void = () => {
    setTimeout(() => {
      this.context?.messageWrapperRef?.current?.scrollTo(0, this.context?.messageWrapperRef?.current?.scrollHeight * 2);
    }, 30);
  }

  enqueueMessageAsync = async (newMessage: Message) => {
    this.queue.enqueue({ type: QueueItemType.TypingControl, isTyping: true });
    this.queue.enqueue({ type: QueueItemType.Message, message: newMessage });
    this._processQueue();
  };

  addMessageInstantAsync = async (newMessage: Message) => {
    let lastId = this.state.messages.at(-1)?.id ?? 0;
    newMessage.id ??= lastId + 1;
    await this._setStateAsync({ messages: [...this.state.messages, newMessage] });
    this.scrollToBottom();
    return newMessage.id;
  }

  addAudioMessageResultAsync = async (newMessage: Message) => {
    if (ENVIRONMENT !== 'production') {
      const lastMessage = this.state.messages[this.state.messages.length - 1];
      if (lastMessage.file !== undefined) {
        newMessage.file = lastMessage.file;
      }
    }
    this._setStateAsync({ messages: [...this.state.messages.slice(0, -1), newMessage] });
  }

  toggleIsAudioMessageProcessing = async (isProcessing: boolean) => {
    await this._setStateAsync({ isAudioMessageProcessing: isProcessing });
  }

  toggleIsAudioMessageRecording = async (isRecording: boolean) => {
    await this._setStateAsync({ isAudioMessageRecording: isRecording });
  }

  addDebugMessage = async (newMessage: DebugMessage) => {
    let newDebugMessagesDict = this.state.debugMessages.set(newMessage.targetMessageId, [...this.state.debugMessages.get(newMessage.targetMessageId) ?? [], newMessage]);
    await this._setStateAsync({ debugMessages: newDebugMessagesDict });
  }

  setIsModeratorTypingAsync = async (isTyping: boolean) => {
    this.queue.enqueue({ type: QueueItemType.TypingControl, isTyping: isTyping });
    this._processQueue();
  }

  addReaction = async (userMessageSentId: number, reaction: string) => {
    let newMessagesArray = this.state.messages.map((x, index) => {
      if (x.id !== userMessageSentId)
        return x;
      return {
        id: x.id,
        side: x.side,
        payload: x.payload,
        reaction: reaction,
        file: x.file,
        isVoiceMessage: x.isVoiceMessage,
      }
    });
    await this._setStateAsync({ messages: [...newMessagesArray] });
    this.scrollToBottom();
  }

  /// Пересобирает список текстовых сообщений, заменяя сообщение после addMessage с придуманным локально id на серверный
  /// Также синхронизирует сообщения в других вкладках и не участвует в проверке LastMessageId по этой причине
  syncMessage = async (clientMessageId: number, message: HubMessageText) => {
    let newMessagesArray = this.state.messages.map<Message>((x, index) => {
      if (x.id !== clientMessageId)
        return x;
      return {
        id: message.id,
        side: message.author === InterviewRole.User ? MessageSide.User : MessageSide.Bot,
        payload: [{
          type: MessagePartType.Text,
          data: message.text
        }],
        reaction: x.reaction,
        file: x.file
      }
    });

    let missedMessage: Message[] = [];
    //if message was added in another browser tab
    if (newMessagesArray.some(x => x.id === message.id) === false) {
      missedMessage = [{
        id: message.id,
        side: message.author === InterviewRole.User ? MessageSide.User : MessageSide.Bot,
        payload: [{
          type: MessagePartType.Text,
          data: message.text
        }],
        reaction: null
      }];
    }

    const newArr = [...newMessagesArray, ...missedMessage];

    await this._setStateAsync({ messages: newArr });
    this.scrollToBottom();
  }

  clear = async () => {
    this.queue = new Queue<QueueItemMessage | QueueItemTypingControl>();
    await this._setStateAsync({ messages: [], isModeratorTyping: false });
  }

  getDebugMessages = (targetMessageId: number) => {
    return this.state.debugMessages.get(targetMessageId) ?? [];
  }

  /// Возвращает сумму сообщений в стейте и в очереди
  getMessagesWithQueued = (): Message[] => {
    let queueStorage = this.queue.getQueueStorage();
    // Содержимое очереди в сообщения
    let queuedMessages = queueStorage
      .filter(item => item.type === QueueItemType.Message)
      .map(item => (item as QueueItemMessage).message);

    return [...this.state.messages, ...queuedMessages];
  };
  render() {
    return (
      <MessagesContext.Provider value={{
        messages: this.state.messages, // Строго говоря это сообщения, которые уже в рендере. Может быть больше сообщений внутри _processQueue
        isModeratorTyping: this.state.isModeratorTyping,
        debugMessages: this.state.debugMessages,
        enqueueMessageAsync: this.enqueueMessageAsync,
        addMessageInstantAsync: this.addMessageInstantAsync,
        setIsModeratorTypingAsync: this.setIsModeratorTypingAsync,
        addReaction: this.addReaction,
        syncMessage: this.syncMessage,
        clear: this.clear,
        scrollToBottom: this.scrollToBottom,
        addDebugMessage: this.addDebugMessage,
        getDebugMessages: this.getDebugMessages,
        getMessagesWithQueued: this.getMessagesWithQueued,
        toggleIsAudioMessageProcessing: this.toggleIsAudioMessageProcessing,
        toggleIsAudioMessageRecording: this.toggleIsAudioMessageRecording,
        addAudioMessageResultAsync: this.addAudioMessageResultAsync,
        isAudioMessageProcessing: this.state.isAudioMessageProcessing,
        isAudioMessageRecording: this.state.isAudioMessageRecording
      }}>
        {this.props.children}
      </MessagesContext.Provider>
    )
  }
}

export default MessagesContextProvider

interface MessagesContextProviderState {
  messages: Message[],
  isModeratorTyping: boolean
  debugMessages: Map<number, DebugMessage[]>,
  isAudioMessageProcessing: boolean,
  isAudioMessageRecording: boolean,
}

interface MessagesContextProviderProps {
  messages: Message[],
}