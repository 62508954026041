import styled, { keyframes } from "styled-components";

const Spinner = () => {
    return (
        <SpinnerBackgroundStyled>
            <SpinnerSVGStyled className="spinner" viewBox="0 0 50 50">
                <SpinnerCircleStyled className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></SpinnerCircleStyled>
            </SpinnerSVGStyled>
        </SpinnerBackgroundStyled>
    );
};

const outerRotateKeyframes = keyframes`
    100% { transform: rotate(360deg); }
`;

const dashAnimationKeyframes = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

const SpinnerSVGStyled = styled.svg`
    animation: ${outerRotateKeyframes} 2s linear infinite;
    z-index: 2;
    width: 50%;
    height: 50%;
    max-width: 50px;
    max-height: 50px;
`;

const SpinnerCircleStyled = styled.circle`
    stroke: #93bfec;
    stroke-linecap: round;
    animation: ${dashAnimationKeyframes} 1.5s ease-in-out infinite;
`;

const SpinnerBackgroundStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
`;

export default Spinner;
