import React from "react";
import { DefaultTheme } from "styled-components";

export type PaletteMode = DefaultTheme["palette"]["mode"];

function createTheme(mode: PaletteMode): DefaultTheme {
    return {
        shape: {
            borderRadius: "10px",
            buttonSize: {
                small: "40px", // TODO: пока неизвестно
                meduim: "50px",
                large: "60px", // TODO: пока неизвестно
            },
        },
        palette: {
            mode: mode,
            common: {
                black: "#000000",
                white: "#FFFFFF",
            },
            background: {
                default: "#FFFFFF",
                paper: "#F5F6F7",
            },
            primary: {
                main: "#000000",
                light: "#666666",
                dark: "#000000",
                contrastColor: "#FFFFFF",
            },
            secondary: {
                main: "#7BA1FF",
                light: "#A0BBFF",
                dark: "#6B96FF",
                contrastColor: "#FFFFFF",
            },
            text: {
                primary: "#000000",
                secondary: "#E0E0E0",
                disabled: "#F5F6F7",
            },
            divider: "#E0E0E0",
            status: {
                success: "#00BB5E",
                warning: "#FF9E57",
                danger: "#EB6464",
                info: "#6B96FF",
            },
            special: {
                voiceInputBackground: "#F3F6FF",
            },
        },
        spaces: {
            space0: "0px",
            space5: "5px",
            space10: "10px",
            space15: "15px",
            space20: "20px",
            space25: "25px",
            space30: "30px",
            space35: "35px",
            space40: "40px",
            space45: "45px",
            space50: "50px",
        },
        // TODO: подогнать под макет
        typography: {
            // H1
            h1: {
                textTransform: "none",
                fontSize: "40px",
                lineHeight: "50px",
                letterSpacing: "-0.2px",
                fontWeight: 600,
            },
            // H2
            h2: {
                textTransform: "none",
                fontSize: "32px",
                lineHeight: "40px",
                letterSpacing: "-0.1px",
                fontWeight: 600,
            },
            // H3
            h3: {
                textTransform: "none",
                fontSize: "25px",
                lineHeight: "30px",
                letterSpacing: "0px",
                fontWeight: 400,
            },
            // Text
            subtitle1: {
                textTransform: "none",
                fontSize: "25px",
                lineHeight: "30px",
                letterSpacing: "0px",
                fontWeight: 400,
            },
            subtitle2: {
                textTransform: "none",
                fontSize: "25px",
                lineHeight: "30px",
                letterSpacing: "0px",
                fontWeight: 600,
            },
            body1: {
                textTransform: "none",
                fontSize: "20px",
                lineHeight: "25px",
                letterSpacing: "0px",
                fontWeight: 400,
            },
            body2: {
                textTransform: "none",
                fontSize: "20px",
                lineHeight: "25px",
                letterSpacing: "0px",
                fontWeight: 600,
            },
            // Tab, Select, File Name
            caption: {
                textTransform: "none",
                fontSize: "15px",
                lineHeight: "20px",
                letterSpacing: "0px",
                fontWeight: 400,
            },
            // Small
            overline: {
                textTransform: "none",
                fontSize: "13px",
                lineHeight: "15px",
                letterSpacing: "0px",
                fontWeight: 400,
            },
            // Button, Status
            button: {
                textTransform: "none",
                fontSize: "13px",
                lineHeight: "20px",
                fontWeight: 700,
            },
        },
        breakpoints: {
            mobile: "480px",
            tablet: "800px", // максимальная ширина каркаса чата
            laptop: "1280px",
            desktop: "1440px",
        },
    };
}

export function useMainTheme(mode: PaletteMode) {

    const theme = React.useMemo(
        () => createTheme(mode),
        [mode]
    );

    return theme;
}
