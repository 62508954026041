import React from "react";
import { MessageVoiceInputContainerStyled, MessageVoiceInputStatusStyled, MessageVoiceInputTimelineItemStyled, MessageVoiceInputTimelineStyled } from "./MessageVoiceInput.styled";
import Stack from "../../Stack/Stack";
import { useTheme } from "styled-components";
import Typography from "../../Typography/Typography";
import useAudioLevel from "../../../services/Audio/useAudioLevel";

interface MessageVoiceInputProps {
    isRecording: boolean;
    value: MediaStream | null;
    time: number;
}

const MessageVoiceInput: React.FC<MessageVoiceInputProps> = (props) => {

    const theme = useTheme();

    const { isRecording, value, time } = props;
    const { getAudioLevel } = useAudioLevel(value);

    const [audioLevels, setAudioLevels] = React.useState<number[]>([]);

    const timelineRef = React.useRef<HTMLDivElement>(null);
    const timelineSize = 200;
    const sampleRate = 100;

    // Длеаем замеры уровня звука с шагом в 100 мс
    React.useEffect(
        () => {
            function onSample() {
                const level = getAudioLevel();

                if (level === null) {
                    return;
                }

                setAudioLevels(x => {
                    return [level, ...x].slice(0, timelineSize);
                });
            }

            const intervalId = window.setInterval(onSample, sampleRate);
            return () => {
                window.clearInterval(intervalId);
                setAudioLevels([]);
            };
        },
        [getAudioLevel]
    );

    // Единажды создаем столбики для таймлайна
    const timelineItems = React.useMemo<React.ReactNode[]>(
        () => {
            const result: React.ReactNode[] = [];

            for (let i = 0; i < timelineSize; i++) {
                result.push(
                    <MessageVoiceInputTimelineItemStyled
                        key={i}
                        $index={i}
                    />
                );
            }

            return result;
        },
        []
    );

    // Перезаписываем высоту столбиков в таймлайне, если что-то изменилось в таймлайне
    React.useEffect(
        () => {
            if (!timelineRef.current) {
                return;
            }

            const maxLevel = audioLevels.length !== 0 ? Math.max(...audioLevels) : 0;

            timelineRef.current.childNodes.forEach((x, i) => {
                const value = i < audioLevels.length
                    ? maxLevel !== 0 ? audioLevels[i] / maxLevel : 0 // Нормализуем значение
                    : 0;

                if (x instanceof HTMLElement) {
                    x.style.height = `max(${value * 100}%, 2px)`;
                }
            });
        },
        [audioLevels]
    );

    const timeDisplayString = React.useMemo<string>(
        () => {
            const timeRounded = Math.abs(Math.round(time));
            const seconds = timeRounded % 60;
            const minutes = Math.ceil((timeRounded - seconds) / 60);

            return `${minutes}:${seconds.toString().padStart(2, "0")}`;
        },
        [time]
    );

    return (
        <MessageVoiceInputContainerStyled>
            <MessageVoiceInputTimelineStyled ref={timelineRef}>
                {timelineItems}
            </MessageVoiceInputTimelineStyled>
            <Stack $direction="row" $alignItems="center" $gap={theme.spaces.space10}>
                <div>
                    <MessageVoiceInputStatusStyled $isActive={isRecording} />
                </div>
                <Typography
                    $variant="caption"
                    color={theme.palette.secondary.main}
                    style={{
                        whiteSpace: "nowrap",
                    }}
                >
                    {timeDisplayString}
                </Typography>
            </Stack>
        </MessageVoiceInputContainerStyled>
    );
};

export default MessageVoiceInput;